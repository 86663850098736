import React, { useEffect, useState } from 'react';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { Avatar, Drawer, DrawerCloseButton, DrawerContent, DrawerOverlay, Icon, IconButton } from '@chakra-ui/react';
import { AiFillHome, AiFillCreditCard, AiOutlineMenu, AiOutlineHistory } from 'react-icons/ai';
import { BiLogOutCircle } from 'react-icons/bi';
import { Aside, Main, Container, MenuHeader, MenuItemsWrapper, MenuItem, MenuIconWrapper, Header, Content, Title } from './styles';
import { useWindowDimensions } from '../hooks/use-window-dimensions';
import { useAppState } from '../context/app';
import { navigateToMagoPlan } from '../utils/main';
import { routes } from '../routes';

const menuItems = [
  {
    path: 'visao-geral',
    label: 'Visão Geral',
    icon: AiFillHome,
  },
  {
    path: 'planos-disponiveis',
    label: 'Planos Disponíveis',
    icon: AiFillCreditCard,
  },
  {
    path: 'pagamentos',
    label: 'Pagamentos',
    icon: AiOutlineHistory,
  },
];

const DefaultLayout: React.FC = () => {
  const navigate = useNavigate();
  const [appState] = useAppState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const { isLaptop, isTablet } = useWindowDimensions();
  const showMenuLabel = !isLaptop || isTablet;

  useEffect(() => {
    if (!isTablet) {
      setOpenDrawer(false);
    }
  }, [isTablet]);

  const handleNavigate = (path: string) => {
    navigate(path);
    if (openDrawer) {
      setOpenDrawer(false);
    }
  };

  const MenuContent = () => (
    <>
      <MenuHeader className='menu-header'>
        <Avatar size='sm' name={appState.company?.name} src={appState.company?.logo} />
        {showMenuLabel && <span>{appState.company?.name}</span>}
      </MenuHeader>
      <hr />
      <MenuItemsWrapper>
        <div>
          {menuItems.map(menuItem => (
            <MenuItem key={menuItem.path} active={window.location.pathname.includes(menuItem.path)} onClick={() => handleNavigate(menuItem.path)}>
              <Icon as={menuItem.icon} />
              {showMenuLabel && menuItem.label}
            </MenuItem>
          ))}
        </div>
        <MenuItem onClick={() => navigateToMagoPlan()}>
          <Icon as={BiLogOutCircle} />
          {showMenuLabel && 'Voltar para Mago Plan'}
        </MenuItem>
      </MenuItemsWrapper>
    </>
  );

  return (
    <Container>
      <Aside>
        <MenuContent />
      </Aside>
      <Drawer isOpen={openDrawer} placement='left' onClose={() => setOpenDrawer(false)}>
        <DrawerOverlay />
        <DrawerContent className='menu-drawer'>
          <DrawerCloseButton />
          <MenuContent />
        </DrawerContent>
      </Drawer>
      <Main>
        <Header>
          {isTablet && (
            <MenuIconWrapper>
              <IconButton aria-label='menu-button' onClick={() => setOpenDrawer(prev => !prev)}>
                <AiOutlineMenu />
              </IconButton>
            </MenuIconWrapper>
          )}
          <Routes>
            {routes.map(route => (
              <Route path={route.path} key={route.path} element={<Title>{route.title}</Title>} />
            ))}
            <Route path='/' element={<Title>{routes[0].title}</Title>} />
          </Routes>
        </Header>
        <Content>
          <Outlet />
        </Content>
      </Main>
    </Container>
  );
};

export default DefaultLayout;
