import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AppError from '../components/atoms/AppError';
import DefaultLayout from '../layouts/default';
import InvoicePage from '../pages/fatura/[invoiceId]';
import PaymentsPage from '../pages/pagamentos';
import AvailablePlansPage from '../pages/planos-disponiveis';
import ChangePlanPage from '../pages/trocar-plano';
import HomePage from '../pages/visao-geral';

export const routes = [
  {
    path: 'visao-geral',
    component: <HomePage />,
    title: 'Visão Geral da conta',
  },
  {
    path: 'planos-disponiveis',
    component: <AvailablePlansPage />,
  },
  {
    path: 'trocar-plano',
    component: <ChangePlanPage />,
    title: 'Mudar plano',
  },
  {
    path: 'fatura/:invoiceId',
    component: <InvoicePage />,
    title: 'Fatura',
  },
  {
    path: 'pagamentos',
    component: <PaymentsPage />,
    title: 'Pagamentos',
  },
];

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path='/:companyId' element={<DefaultLayout />}>
        <Route index element={<HomePage />} />
        {routes.map(route => (
          <Route path={route.path} key={route.path} element={route.component} />
        ))}
      </Route>
      <Route path='error' element={<AppError />} />
    </Routes>
  );
};

export default AppRoutes;
