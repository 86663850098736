import React, { useEffect, useState } from 'react';
import Stripe from 'stripe';

import { Table, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react';
import { Quantity, SubscriptionItem } from './styles';
import { SubscriptionItemType } from '../../../pages/trocar-plano';
import { PriceMetadataType } from '../../../types/price';
import { mapPeriodicityToText } from '../../../utils/plan';
import theme from '../../../theme';
// import { Container } from './styles';

export interface CurrentPlanTableProps {
  subscription?: Stripe.Subscription;
}

const CurrentPlanTable: React.FC<CurrentPlanTableProps> = ({ subscription }) => {
  const [subscriptionItems, setSubscriptionItems] = useState<SubscriptionItemType[]>([]);
  const [totalValue, setTotalValue] = useState(0);

  useEffect(() => {
    if (subscription) {
      const items: SubscriptionItemType[] = [];
      const totalValue = subscription.items.data.reduce((acc, item) => {
        if (item.price.metadata.type === PriceMetadataType.PLAN) {
          items.push({
            id: item.price.id,
            name: `Plano | ${item.price.metadata.members} membros`,
            detail: `Cobrança ${mapPeriodicityToText(item.price.recurring, true)}`,
            metadata: item.price.metadata! as SubscriptionItemType['metadata'],
            unitPrice: item.price.unit_amount! / 100,
            quantity: 1,
          });
          return acc + item.price.unit_amount!;
        }
        if (item.price.metadata.type === PriceMetadataType.ADDITIONAL_MEMBER) {
          items.push({
            id: item.price.id,
            name: 'Profissional Adicional',
            metadata: item.price.metadata! as SubscriptionItemType['metadata'],
            unitPrice: item.price.unit_amount! / 100,
            quantity: item.quantity!,
          });
          return acc + item.price.unit_amount! * item.quantity!;
        }
        return acc;
      }, 0);
      setTotalValue(totalValue / 100);
      setSubscriptionItems(items);
    }
  }, [subscription]);

  const getTableLines = () => {
    if (!subscription) {
      return (
        <Tr>
          <Td colSpan={2}>
            <SubscriptionItem>
              <span>Plano Grátis</span>
            </SubscriptionItem>
          </Td>
          <Td isNumeric>Grátis</Td>
        </Tr>
      );
    }

    return subscriptionItems.map(item => (
      <Tr key={item.id}>
        <Td colSpan={2}>
          <SubscriptionItem>
            <div>
              <span>{item.name}</span>
              {item.detail ? (
                <span>{item.detail}</span>
              ) : (
                <Quantity>
                  <span>Quantidade: {item.quantity}</span>
                </Quantity>
              )}
            </div>
          </SubscriptionItem>
        </Td>
        <Td isNumeric>R$ {(item.quantity * item.unitPrice).toFixed(2).replace('.', ',')}</Td>
      </Tr>
    ));
  };

  return (
    <TableContainer>
      <Table variant='simple'>
        <Thead>
          <Tr>
            <Th colSpan={2}>Item</Th>
            <Th isNumeric>Preço</Th>
          </Tr>
        </Thead>
        <Tbody>{getTableLines()}</Tbody>
        <Tfoot>
          <Tr>
            <Th colSpan={2}>Total</Th>
            <Th isNumeric sx={{ fontSize: theme.font.size.md }}>
              R$ {totalValue.toFixed(2).replace('.', ',')}
            </Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

export default CurrentPlanTable;
