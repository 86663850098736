import { AppState } from '.';
import { Action } from '../types';
import { AppStateEnum } from './enum';

export const appReducer = (state: AppState, { type, ...action }: Action<AppStateEnum>): AppState => {
  switch (type) {
    case AppStateEnum.START_LOADING:
      return { ...state, loading: true };
    case AppStateEnum.STOP_LOADING:
      return { ...state, loading: false };
    case AppStateEnum.ADD_NEW_CARD:
      if (state.customer) {
        return { ...state, customer: { ...state.customer, cards: [...(state.customer?.cards || []), action.payload] } };
      }
      return state;
    case AppStateEnum.REMOVE_CARD:
      return { ...state, customer: { ...state.customer!, cards: state.customer?.cards.filter(card => card.id !== action.payload.cardId) || [] } };
    case AppStateEnum.CANCEL_PLAN:
      return { ...state, customer: { ...state.customer, data: state.customer?.data!, subscription: action.payload.subscription, cards: [], upcoming: undefined } };
    case AppStateEnum.SET_APP_STATE:
      let newState;
      if (typeof action.payload === 'function') {
        newState = action.payload(state.customer);
      } else {
        newState = action.payload;
      }
      return { ...state, ...newState, loading: false };
    case AppStateEnum.SET_REFETCH_ALL:
      return { ...state, refetchAll: true };
    case AppStateEnum.SET_CUSTOMER_ADDITIONAL_INFO:
      let customer;
      if (typeof action.payload === 'function') {
        customer = action.payload(state.customer);
      } else {
        customer = { ...state.customer, ...action.payload };
      }
      return { ...state, customer };
    case AppStateEnum.SET_ERROR:
      return { ...state, loading: false, error: action.error };
    case AppStateEnum.SET_COMPANY_CUSTOMER:
      return { ...state, loading: false, company: action.payload.company, customer: action.payload.customer };
    default:
      throw new Error('Missing Action type');
  }
};
