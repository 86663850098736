import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppState } from './context/app';
import AppLoader from './components/atoms/AppLoader';
import AppRoutes from './routes';
import LoginModal, { LoginModalProps } from './components/organisms/LoginModal';
import api from './services/api';
import authConfig from './config/auth';
import { EventEnum } from './types/events';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const loginModalDefaultProps: LoginModalProps = {
  open: false,
  handleClose: () => {},
  handleSubmit: () => Promise.resolve(),
};

const App: React.FC = () => {
  const [{ loading }] = useAppState();
  const [loginModalProps, setLoginModalProps] = useState(loginModalDefaultProps);

  useEffect(() => {
    window.addEventListener(EventEnum.REAUTHENTICATION_ERROR, () => {
      setLoginModalProps(prev => ({
        ...prev,
        open: true,
        handleSubmit: async data => {
          const res = await api.post('/auth/login', data);
          localStorage.setItem(authConfig.storageTokenKeyName, res.data.session.token);
          window.location.reload();
          setLoginModalProps(loginModalDefaultProps);
        },
        handleClose: () => setLoginModalProps(loginModalDefaultProps),
      }));
    });
  }, []);

  return (
    <Container>
      {loading ? <AppLoader label='Aguarde enquanto buscamos seus dados' /> : <AppRoutes />}
      <LoginModal {...loginModalProps} />
    </Container>
  );
};

export default App;
