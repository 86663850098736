import Stripe from 'stripe';
import diners from '../assets/images/diners.png';
import amex from '../assets/images/amex.png';
import mastercard from '../assets/images/mastercard.png';
import visa from '../assets/images/visa.png';
import discovery from '../assets/images/discover.png';
import { Card } from '../types/card';

export type CardBrand = 'Visa' | 'MasterCard' | 'AmericanExpress' | 'Diners' | 'Discovery' | undefined;

const visaRegex = /^4[0-9]{6,}$/;
const masterRegex = /^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/;
const amexRegex = /^3[47][0-9]{5,}$/;
const dinersRegex = /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/;
const discoverRegex = /^6(?:011|5[0-9]{2})[0-9]{3,}$/;
export const getCardBrand = (cardNumber: string): CardBrand => {
  const trimmedCardNumber = cardNumber.replaceAll(' ', '');
  if (visaRegex.test(trimmedCardNumber)) {
    return 'Visa';
  }
  if (masterRegex.test(trimmedCardNumber)) {
    return 'MasterCard';
  }
  if (amexRegex.test(trimmedCardNumber)) {
    return 'AmericanExpress';
  }
  if (dinersRegex.test(trimmedCardNumber)) {
    return 'Diners';
  }
  if (discoverRegex.test(trimmedCardNumber)) {
    return 'Discovery';
  }
};

export const getCardBrandImage = (brand: CardBrand) => {
  if (brand === undefined) return undefined;
  const images = {
    Visa: visa,
    MasterCard: mastercard,
    AmericanExpress: amex,
    Diners: diners,
    Discovery: discovery,
  };
  const imagesLower = {
    visa,
    masterCard: mastercard,
    americanExpress: amex,
    diners,
    discovery,
  };
  return images[brand] || imagesLower[brand as keyof typeof imagesLower];
};

export const getCardBrandReadableName = (brand: CardBrand) => {
  if (brand === undefined) return undefined;
  const names = {
    visa: 'Visa',
    mastercard: 'MasterCard',
    amex: 'American Express',
    diners: 'Diners Club',
    discovery: 'Discovery',
  };

  const upperCaseNames = {
    Visa: names.visa,
    MasterCard: names.mastercard,
    AmericanExpress: names.amex,
    Diners: names.diners,
    Discovery: names.discovery,
  };
  const upperCaseNamesLower = {
    visa: names.visa,
    masterCard: names.mastercard,
    americanExpress: names.amex,
    diners: names.diners,
    discovery: names.discovery,
  };
  return upperCaseNames[brand] || upperCaseNamesLower[brand as keyof typeof upperCaseNamesLower];
};

type Rule = {
  required: {
    value: boolean;
    message?: string;
  };
  length?: {
    value: number[];
    message?: string;
  };
  custom?: (value: any) => string | undefined;
};
const rules: Record<keyof Card, Rule> = {
  cardNumber: {
    required: {
      value: true,
    },
    length: { value: [14, 15, 16] },
  },
  cardHolder: {
    required: {
      value: true,
    },
  },
  validity: {
    required: {
      value: true,
    },
    length: { value: [5], message: 'Deve ter ao menos 4 caracteres' },
    custom: (value: string) => {
      const [month, year] = value.split('/');
      const date = new Date(Number(`20${year}`), Number(month));
      if (date < new Date()) {
        return 'Data inválida';
      }
    },
  },
  cvv: {
    required: {
      value: true,
    },
    length: { value: [3, 4] },
  },
};

export const validateCardData = (data: Card) => {
  const errors: Partial<Record<keyof Card, string>> = {};
  Object.keys(data).forEach(key => {
    const objKey = key as keyof Card;

    const fieldRules = rules[objKey];
    if ((fieldRules.required && data[objKey]) || (fieldRules.length && !fieldRules.length.value.includes(data[objKey].length)) || (fieldRules.custom && !fieldRules.custom(data[objKey]))) {
      delete errors[objKey];
    }
    if (fieldRules.required && !data[objKey]) {
      if (!errors[objKey]) errors[objKey]! = fieldRules.required.message || 'Campo obrigatório';
    } else if (fieldRules.length && !fieldRules.length.value.includes(data[objKey].length)) {
      if (!errors[objKey]) errors[objKey]! = fieldRules.length.message || `Deve ter ao menos ${Math.min(...fieldRules.length.value)} caracteres`;
    } else if (fieldRules.custom) {
      const validationMessage = fieldRules.custom(data[objKey]);
      if (validationMessage && !errors[objKey]) errors[objKey]! = validationMessage;
    }
  });
  return errors;
};

export const getCustomerCardFromSource = (source?: Stripe.CustomerSource) => {
  if (!source) return undefined;
  if (source && 'brand' in source) {
    return source;
  }
  if (source && 'card' in source && source.card) {
    return source.card as Stripe.Card;
  }
};

export const getCustomerCardFromPaymentMethod = (paymentMethod?: Stripe.PaymentMethod) => {
  return paymentMethod?.card! as unknown as Stripe.Card;
};
