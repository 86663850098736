import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: ${props => props.theme.spacing.default};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  > div {
    margin-top: ${props => props.theme.spacing.default};
  }
`;

export const ColumnCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.green[600]};
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`;

export const ColumnCardTitle = styled.span`
  font-weight: 600;
`;

export const ColumnCardContent = styled.span`
  padding: ${props => props.theme.spacing.default};
  .chakra-spinner {
    margin-left: 50%;
    translate: -50%;
  }
  tfoot {
    tr.discount {
      th {
        /* :first-child {
            display: flex;
            align-items: center;
          } */
        span {
          margin-left: ${({ theme }) => theme.spacing.xs};
        }
        svg {
          display: unset;
        }
        font-size: ${({ theme }) => theme.font.size.md};
        color: ${({ theme }) => theme.colors.gray[500]};
        font-weight: 500;
      }
    }
    tr.total {
      th {
        font-size: ${({ theme }) => theme.font.size.lg};
        color: ${({ theme }) => theme.colors.green[500]};
      }
    }
  }
`;

export const SelectQuantity = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray[500]};
  font-size: ${({ theme }) => theme.font.size.md};

  button {
    padding: ${({ theme }) => theme.spacing.xs};
    min-width: unset;
    font-size: 14px;
    :focus {
      box-shadow: unset;
    }
  }
  span {
    margin-right: ${props => props.theme.spacing.xs};
  }
  div {
    display: flex;
    align-items: center;
  }
`;

export const SubscriptionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    :first-child {
      display: flex;
      flex-direction: column;

      span {
        :last-child {
          font-size: ${({ theme }) => theme.font.size.md};
          color: ${({ theme }) => theme.colors.gray[500]};
        }
      }
    }
  }
`;

export const AdditionalItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  div {
    :first-child {
      display: flex;
      flex-direction: column;
    }
    :last-child {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      input {
        text-align: center;
        padding: 0 ${({ theme }) => theme.spacing.sm};
      }
    }
  }
`;

export const ColumnCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing.sm};
  box-shadow: ${props => props.theme.style['box-shadow']};
  border-radius: ${props => props.theme.style['border-radius'].default};

  ${ColumnCardTitle} {
  }
`;

export const NextSteps = styled.div`
  ul {
    list-style-position: inside;
    padding-left: ${({ theme }) => theme.spacing.md};
  }
`;
