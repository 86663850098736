import styled from 'styled-components';

export const Container = styled.div`
  width: 70%;
`;

export const CompanyInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.gray[200]};
  padding: ${props => props.theme.spacing.sm} 0;
  span {
    width: 50%;

    :first-child {
      color: ${({ theme }) => theme.colors.gray[500]};
      font-size: ${({ theme }) => theme.font.size.default};
    }
    :last-child {
      font-size: ${({ theme }) => theme.font.size.default};
    }
  }
`;
