import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { FormControl, FormErrorMessage, Grid, GridItem, Input, InputGroup, InputRightElement, ScaleFade, Stack } from '@chakra-ui/react';
import { Card } from '../../../../types/card';
import { InputItem } from './styles';
import cvv from '../../../../assets/images/cvv.png';
import { CardBrand, getCardBrandImage, getCardBrand } from '../../../../utils/card';
import theme from '../../../../theme';

export interface CreateCardFormProps {
  data: Card;
  onChange: (field: keyof Card, value: any) => void;
  errors?: Partial<Record<keyof Card, string>>;
}

const CreateCardForm: React.FC<CreateCardFormProps> = ({ data, onChange, errors }) => {
  const [cardBrand, setCardBrand] = useState<CardBrand>();

  useEffect(() => {
    setCardBrand(getCardBrand(data.cardNumber));
  }, [data.cardNumber]);

  return (
    <Stack spacing={4}>
      <InputItem>
        <label htmlFor='card-holder'>Nome no cartão</label>
        <FormControl isInvalid={!!errors?.cardHolder}>
          <Input
            isInvalid={!!errors?.cardHolder}
            errorBorderColor='red.400'
            id='card-holder'
            variant='outline'
            placeholder='JOSE DA SILVA'
            onChange={e => onChange('cardHolder', e.target.value)}
            value={data.cardHolder}
          />
          <FormErrorMessage>{errors?.cardHolder}</FormErrorMessage>
        </FormControl>
      </InputItem>
      <InputItem>
        <label htmlFor='card-number'>Número do cartão</label>
        <FormControl isInvalid={!!errors?.cardNumber}>
          <InputGroup>
            <Input
              errorBorderColor='red.400'
              id='card-number'
              as={InputMask}
              mask='**** **** **** ****'
              maskChar={null}
              placeholder='1234 1234 1234 1234'
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={e => onChange('cardNumber', e.target.value.replaceAll(' ', ''))}
              value={data.cardNumber}
            />
            <ScaleFade initialScale={0.9} unmountOnExit in={!!cardBrand}>
              <InputRightElement width='35px' mr={theme.spacing.xs}>
                <img src={getCardBrandImage(cardBrand)} alt='diners' />
              </InputRightElement>
            </ScaleFade>
          </InputGroup>
          <FormErrorMessage>{errors?.cardNumber}</FormErrorMessage>
        </FormControl>
      </InputItem>
      <Grid templateColumns='repeat(2, 1fr)' gap={6}>
        <GridItem w='100%'>
          <label htmlFor='validity'>Validade</label>
          <FormControl isInvalid={!!errors?.validity}>
            <Input
              isInvalid={!!errors?.validity}
              errorBorderColor='red.400'
              id='validity'
              as={InputMask}
              mask='99/99'
              maskChar={null}
              placeholder='MM/YY'
              onChange={e => onChange('validity', e.target.value)}
              value={data.validity}
            />
            <FormErrorMessage>{errors?.validity}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem w='100%'>
          <label htmlFor='cvv'>Cód. Segurança</label>
          <FormControl isInvalid={!!errors?.cvv}>
            <InputGroup>
              <Input
                id='cvv'
                errorBorderColor='red.400'
                isInvalid={!!errors?.cvv}
                placeholder='CVV'
                as={InputMask}
                mask='9999'
                maskChar={null}
                onChange={e => onChange('cvv', e.target.value)}
                value={data.cvv}
              />
              <InputRightElement width='40px' mr={theme.spacing.xs}>
                <img src={cvv} alt='cvv' />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors?.cvv}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default CreateCardForm;
