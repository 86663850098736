import React from 'react';
import { Skeleton } from '@chakra-ui/react';
import Stripe from 'stripe';
import { Content, PriceName, PriceMembers, PriceCurrency, PriceInstallments, PriceValue, PriceInstallmentPrice, PriceTotalPrice, UserMonthlyPrice } from './styles';
import { mapPeriodicityToText, mapPeriodicityToMonths } from '../../../../utils/plan';

export interface PriceProps {
  data: Stripe.Price;
}

const PriceSkeleton = () => {
  return (
    <Content>
      <PriceName>
        <Skeleton width='200px' height='22px' />
      </PriceName>
      <PriceMembers>
        <Skeleton marginTop='5px' marginBottom='5px' width='100px' height='18px' />
      </PriceMembers>
      <PriceInstallmentPrice>
        <div>
          <PriceInstallments>
            <Skeleton marginBottom='2px' width='15px' height='15px' />
          </PriceInstallments>
          <PriceCurrency>
            <Skeleton width='15px' height='15px' />
          </PriceCurrency>
        </div>
        <PriceValue>
          <Skeleton width='100px' height='30px' />
        </PriceValue>
      </PriceInstallmentPrice>
      <PriceTotalPrice>
        <Skeleton marginTop='5px' width='150px' height='18px' />
      </PriceTotalPrice>
    </Content>
  );
};

const Price: React.FC<PriceProps> & { Skeleton: React.FC } = ({ data }) => {
  const members = Number(data.metadata.members);
  const planValue = (data.unit_amount! / 100).toFixed(2).replace('.', ',');
  const userMonthlyValue = (data.unit_amount! / (100 * members * mapPeriodicityToMonths(data.recurring))).toFixed(2).replace('.', ',');
  return (
    <Content>
      <PriceName>{mapPeriodicityToText(data.recurring, true)}</PriceName>
      {/* <PriceMembers>{members === 1 ? '1 membro incluso' : `${members} membros inclusos`}</PriceMembers> */}
      <PriceInstallmentPrice>
        <div>
          <PriceInstallments>1x</PriceInstallments>
          <PriceCurrency>R$</PriceCurrency>
        </div>
        <PriceValue>{planValue}</PriceValue>
      </PriceInstallmentPrice>
      <PriceTotalPrice>
        R$ {planValue} por {mapPeriodicityToText(data.recurring)}
      </PriceTotalPrice>
      <UserMonthlyPrice>R$ {userMonthlyValue} por usuário/mês</UserMonthlyPrice>
    </Content>
  );
};

Price.Skeleton = PriceSkeleton;

export default Price;
