import React from 'react';
import AppContextProvider from './app';

/**
 * Recommended Chrome Ext: https://github.com/deeppatel234/react-context-devtool
 */
const RootContext: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <AppContextProvider>{children}</AppContextProvider>;
};

export default RootContext;
