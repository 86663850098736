/* eslint-disable prettier/prettier */
import styled from 'styled-components';

export const Container = styled.div<{ selected?: boolean; clickable?: boolean; error?: boolean }>`
  ${({ theme, selected, error }) =>
    error
      ? `
      border: 2px solid ${theme.colors.red[500]};
      box-shadow: 0 0 0 3px #e53e3e52 !important;
    ` : selected ? `
      border: 2px solid ${theme.colors.green[500]};
      box-shadow: 0 0 0 3px #aedbbf !important;
    ` : `
      box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    `
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: ${props => props.clickable && 'pointer'};
  border-radius: ${({ theme }) => theme.style['border-radius'].default};
  padding: ${({ theme }) => theme.spacing.default};
  > div {
    width: 100%;
    display: flex;
    flex-direction: row;

  }
  > div > div:last-child {
    display: flex;
    width: 100%;
    margin-left: ${({ theme }) => theme.spacing.default};
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    > div {
      margin-top: ${({ theme }) => theme.spacing.xs};
      display: flex;
    }
  }
`;

export const CardBrand = styled.span`
  font-weight: 600;
`;

export const CardNumber = styled.span`
  font-weight: 400;
  font-size: ${({ theme }) => theme.font.size.md};
  margin-right: ${({ theme }) => theme.spacing.default};
  color: ${({ theme }) => theme.colors.gray[500]};
`;
export const CardValidity = styled.span`
  font-weight: 400;
  font-size: ${({ theme }) => theme.font.size.md};
  color: ${({ theme }) => theme.colors.gray[500]};
`;

export const CardBrandImage = styled.img`
  height: 48px;
`;
