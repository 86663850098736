import React from 'react';
import AppError from '../AppError';

type Props = { children: React.ReactNode };
export class ErrorBoundary extends React.Component<Props, { error: any }> {
  constructor(props: Props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError(error: any) {
    // Atualiza o state para que a próxima renderização mostre a UI alternativa.
    return { error };
  }

  componentDidCatch(error: any) {
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      error,
    });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      // Você pode renderizar qualquer UI alternativa
      return <AppError error={error} />;
    }

    return children;
  }
}
