import React, { useMemo } from 'react';
import Stripe from 'stripe';
import { parseStripeValueToPrice } from '../../../../utils/stripe';
import { Content, PriceName, PriceMembers, PriceCurrency, PriceValue, PriceInstallmentPrice } from './styles';

// import { Container } from './styles';

const Total: React.FC<{ data: Stripe.Subscription }> = ({ data }) => {
  const total = useMemo(() => {
    return data.items.data.reduce((acc, item) => acc + item.price.unit_amount! * item.quantity!, 0);
  }, [data.items.data]);

  const totalMembers = useMemo(() => {
    return data.items.data.reduce((acc, item) => {
      if (item.price.metadata.type === 'plan') {
        return acc + Number(item.price.metadata.members);
      }
      if (item.price.metadata.type === 'additionalMember') {
        return acc + item.quantity!;
      }
      return acc;
    }, 0);
  }, [data.items.data]);

  return (
    <Content>
      <PriceName>Total</PriceName>
      <PriceMembers>Total de {totalMembers} membros</PriceMembers>
      <PriceInstallmentPrice>
        {!false && (
          <div>
            <PriceCurrency>R$</PriceCurrency>
          </div>
        )}
        <PriceValue>{parseStripeValueToPrice(total)}</PriceValue>
      </PriceInstallmentPrice>
    </Content>
  );
};

export default Total;
