import React, { useEffect, useState } from 'react';
import { Skeleton, useToast } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import Stripe from 'stripe';
import { CanceledError } from 'axios';
import { FillPage } from './styles';
import api from '../../services/api';
import { isAxiosError } from '../../utils/axios';
import { PriceMetadataType } from '../../types/price';
import { mapPeriodicityToText } from '../../utils/plan';

import InvoiceUnpaid, { InvoiceUnpaidProps } from './InvoiceUnpaid';
import { ExpandedInvoice, InvoiceResult, Line } from './types';
import { InvoicePaid } from './InvoicePaid';
import { useAppState } from '../../context/app';
import { setCustomerAdditionalInfoAction } from '../../context/app/actions';

const InvoicePage: React.FC = () => {
  const [{ customer }] = useAppState();
  const params = useParams();
  const navigate = useNavigate();
  const [invoiceData, setInvoiceData] = useState<{ loading: boolean; error?: string; result?: InvoiceResult }>({
    loading: true,
  });
  const toast = useToast();

  useEffect(() => {
    if (params.invoiceId) {
      api
        .get<ExpandedInvoice>(`/customers/${customer.data?.id!}/invoice/${params.invoiceId}`)
        .then(res => {
          // Parsing lines into items and calculating the total value
          const obj = res.data.lines.data.reduce<{ total: number; lines: Line[]; planInvoinceLine?: Stripe.InvoiceLineItem; additionalMemberInvoinceLine?: Stripe.InvoiceLineItem }>(
            (current, item) => {
              if (item.price?.metadata.type === PriceMetadataType.PLAN) {
                current.lines.unshift({
                  id: item.id,
                  displayName: `Plano | ${item.price.metadata.members} membros`,
                  description: `Cobrança ${mapPeriodicityToText(item.price.recurring, true)}`,
                  type: PriceMetadataType.PLAN,
                  value: item.price?.unit_amount! / 100,
                });
              }

              if (item.price?.metadata.type === PriceMetadataType.ADDITIONAL_MEMBER) {
                current.lines.push({
                  id: item.id,
                  displayName: 'Profissional Adicional',
                  description: `Quantidade ${item.quantity}`,
                  type: PriceMetadataType.ADDITIONAL_MEMBER,
                  value: (item.price?.unit_amount! * item.quantity!) / 100,
                });
              }
              return { ...current, total: (current.total + (item.price!.unit_amount! || 0)) / 100 };
            },
            { total: 0, lines: [] }
          );
          setInvoiceData(prev => ({
            ...prev,
            loading: false,
            result: {
              ...obj,
              invoice: res.data,
            },
            error: undefined,
          }));
        })
        .catch(err => {
          if (err instanceof CanceledError) return;
          if (isAxiosError(err)) {
            setInvoiceData(prev => ({ ...prev, loading: false, error: err.response?.data?.message || 'Ocorreu um erro ao buscar a fatura' }));
          }
        });
    }
  }, [customer.data?.id, params.invoiceId]);

  const handleConfirmPayment: InvoiceUnpaidProps['onPay'] = async cardId => {
    try {
      await api.post<ExpandedInvoice>(`/customers/${customer.data?.id!}/invoice/${params.invoiceId}/pay`, { card: cardId });
      toast({
        status: 'success',
        isClosable: true,
        title: 'Fatura paga com sucesso',
      });
      setCustomerAdditionalInfoAction(prev => ({ ...prev, subscription: { ...prev.subscription!, status: 'active' } }));
      navigate(`/${params.companyId}`);
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        toast({
          status: 'error',
          isClosable: true,
          title: error.response.data.message,
        });
      }
    }
  };

  if (!!invoiceData.error) {
    return (
      <FillPage>
        <div>{invoiceData.error}</div>
      </FillPage>
    );
  }

  switch (invoiceData.result?.invoice.status) {
    case 'open':
      return <InvoiceUnpaid data={invoiceData.result} loading={invoiceData.loading} onPay={handleConfirmPayment} />;
    case 'paid':
      return <InvoicePaid data={invoiceData.result} />;
    default:
      if (invoiceData.loading) {
        return <Skeleton height='100px' />;
      }
      return <></>;
  }
};

export default InvoicePage;
