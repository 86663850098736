import axios, { AxiosError } from 'axios';
import authConfig from '../config/auth';
import { EventEnum } from '../types/events';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Cache-Control': 'max-age=0',
  },
});

const errorResponses: AxiosError[] = [];
api.interceptors.response.use(
  config => config,
  error => {
    if (error.response && error.response.data?.code === 'REAUTHENTICATION_REQUIRED') {
      dispatchEvent(new CustomEvent(EventEnum.REAUTHENTICATION_ERROR, { detail: { error: errorResponses } }));
      return error;
    }
    return Promise.reject(error);
  }
);

const isCalling = new Map();
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem(authConfig.storageTokenKeyName);
    if (!config.headers) config.headers = {};
    config.headers.Authorization = `Bearer ${token}`;

    // Preventing same api call twice in sequence
    const isCallingUrl = isCalling.get(config.url);
    if (isCallingUrl) {
      isCalling.delete(config.url);
      return {
        ...config,
        cancelToken: new axios.CancelToken(cancel => cancel('Cancel repeated request')),
      };
    }
    isCalling.set(config.url, config.url);
    setTimeout(() => {
      isCalling.delete(config.url);
    }, 100);
    return config;
  },
  error => Promise.reject(error)
);

export default api;
