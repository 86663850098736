import React, { useEffect, useState } from 'react';
import { Button, Flex, Table, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react';
import Stripe from 'stripe';
import { Container, Column, ColumnCard, ColumnCardTitle, ColumnCardContent, ColumnCardHeader, InvoiceLine } from '../styles';
import { useAppState } from '../../../context/app';
import { delay } from '../../../utils/main';
import { addNewCardAction } from '../../../context/app/actions';
import CardsDialog, { CardsDialogProps } from '../../../components/organisms/CardsDialog';
import PaymentCard from '../../../components/molecules/PaymentCard';
import { InvoiceResult } from '../types';
import { getCustomerCardFromSource } from '../../../utils/card';

export interface InvoiceUnpaidProps {
  data: InvoiceResult;
  loading?: boolean;
  onPay: (cardId: string) => Promise<void>;
}

const InvoiceUnpaid: React.FC<InvoiceUnpaidProps> = ({ data, loading, onPay }) => {
  const [{ customer }, dispatch] = useAppState();
  const [openCardDialog, setOpenCardDialog] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [selectedCard, setSelectedCard] = useState<Stripe.Card>();

  useEffect(() => {
    const paymenteIntent = (data.invoice.payment_intent! as Stripe.PaymentIntent).last_payment_error;
    if (paymenteIntent && paymenteIntent.source) {
      const card = getCustomerCardFromSource(paymenteIntent.source);
      if (card) {
        setSelectedCard(card);
      }
    }
  }, [data]);

  const handleAddNewCard: CardsDialogProps['onAddNewCard'] = async data => {
    await delay(1000);
    const response = await addNewCardAction(dispatch, customer.data?.id!, data);
    return response;
  };

  const handleSubmitSelectedCard = (card: Stripe.Card | undefined) => {
    setSelectedCard(card);
    setOpenCardDialog(false);
  };

  const handleCloseCardsDialog = () => {
    setOpenCardDialog(false);
  };

  const handleSubmit = async () => {
    setLoadingSubmit(true);
    await onPay(selectedCard?.id!);
    setLoadingSubmit(false);
  };

  return (
    <>
      <Container>
        <h1>Pague a fatura para prosseguir com a assinatura do plano</h1>
        <Column>
          <ColumnCard>
            <ColumnCardTitle>Items</ColumnCardTitle>
            <ColumnCardContent>
              <TableContainer>
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th colSpan={2}>Item</Th>
                      <Th isNumeric>Preço</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data?.lines.map(line => (
                      <Tr key={line.id}>
                        <Td colSpan={2}>
                          <InvoiceLine>
                            <div>
                              <span>{line.displayName}</span>
                              <span>{line.description}</span>
                            </div>
                          </InvoiceLine>
                        </Td>
                        <Td isNumeric>R$ {line.value.toFixed(2).replace('.', ',')}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                  <Tfoot>
                    <Tr className='total'>
                      <Th colSpan={2}>Total</Th>
                      <Th isNumeric>R$ {data.total.toFixed(2).replace('.', ',')}</Th>
                    </Tr>
                  </Tfoot>
                </Table>
              </TableContainer>
            </ColumnCardContent>
          </ColumnCard>
          <ColumnCard>
            <ColumnCardHeader>
              <ColumnCardTitle>Pagamento</ColumnCardTitle>
              <p onClick={() => setOpenCardDialog(true)}>Editar</p>
            </ColumnCardHeader>
            <ColumnCardContent>
              <PaymentCard loading={loading} selected data={selectedCard} />
            </ColumnCardContent>
          </ColumnCard>
          <Flex width='100%' justify='flex-end'>
            <Button
              bgColor='green.500'
              color='white'
              isLoading={loadingSubmit}
              isDisabled={!selectedCard || loadingSubmit}
              title='ssss'
              _hover={{ backgroundColor: 'green.600' }}
              _focus={{ boxShadow: '0 0 0 3px green.200' }}
              size='md'
              onClick={handleSubmit}
            >
              Confirmar
            </Button>
          </Flex>
        </Column>
      </Container>
      <CardsDialog
        onSubmitSelectedCard={handleSubmitSelectedCard}
        onAddNewCard={handleAddNewCard}
        selectedCard={selectedCard}
        cards={customer!.cards}
        open={openCardDialog}
        onClose={handleCloseCardsDialog}
      />
    </>
  );
};

export default InvoiceUnpaid;
