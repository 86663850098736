import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  > h1 {
    font-size: ${({ theme }) => theme.font.size.xl} !important;
    color: ${({ theme }) => theme.colors.green[500]};
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  > div {
    margin-top: ${props => props.theme.spacing.default};
  }
`;

export const ColumnCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing.sm};
  box-shadow: ${props => props.theme.style['box-shadow']};
  border-radius: ${props => props.theme.style['border-radius'].default};
`;

export const ColumnCardTitle = styled.span`
  font-weight: 600;
`;

export const ColumnCardContent = styled.span`
  padding: ${props => props.theme.spacing.default};
  tfoot {
    tr.discount {
      th {
        /* :first-child {
            display: flex;
            align-items: center;
          } */
        span {
          margin-left: ${({ theme }) => theme.spacing.xs};
        }
        svg {
          display: unset;
        }
        font-size: ${({ theme }) => theme.font.size.md};
        color: ${({ theme }) => theme.colors.gray[500]};
        font-weight: 500;
      }
    }
    tr.total {
      th {
        font-size: ${({ theme }) => theme.font.size.lg};
        color: ${({ theme }) => theme.colors.green[500]};
      }
    }
  }
`;

export const ColumnCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.green[600]};
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`;

export const FillPage = styled.div`
  height: 100%;
`;

export const InvoiceLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    :first-child {
      display: flex;
      flex-direction: column;

      span {
        :last-child {
          font-size: ${({ theme }) => theme.font.size.md};
          color: ${({ theme }) => theme.colors.gray[500]};
        }
      }
    }
  }
`;
