import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import Stripe from 'stripe';
import { Company } from '../../types/company';
import { Dispatcher } from '../types';
import { getCustomerCompanyInfoAction, setRefetchAllAction } from './actions';
import { AppStateEnum } from './enum';
import { appReducer } from './reducer';
import authConfig from '../../config/auth';
import { EventEnum } from '../../types/events';

export type AppState = {
  loading: boolean;
  error?: any;
  company?: Company; // mago plan company
  refetchAll: boolean;
  customer: {
    data?: Stripe.Customer;
    subscription?: Stripe.Subscription;
    subscriptionSchedule?: Stripe.SubscriptionSchedule;
    upcoming?: Stripe.Invoice;
    cards: Stripe.Card[];
  };
};

const initialState: AppState = {
  loading: true,
  refetchAll: true,
  customer: {
    cards: [],
  },
};

const AppContext = createContext<[AppState, Dispatcher<AppStateEnum>]>([initialState, () => ({})]);
AppContext.displayName = 'AppContext';

const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const match = useMatch('/:companyId/*');

  if (state.error && state.error.message) {
    throw state.error;
  }

  useEffect(() => {
    const params = new URLSearchParams(document.location.search.substring(1));
    const token = params.get('token');
    const storagedToken = localStorage.getItem(authConfig.storageTokenKeyName);
    if (token) {
      localStorage.setItem(authConfig.storageTokenKeyName, token);
    }
    if (!token && !storagedToken) {
      dispatchEvent(new CustomEvent(EventEnum.REAUTHENTICATION_ERROR, { detail: { error: { response: { data: { code: 'REAUTHENTICATION_REQUIRED' } } } } }));
    }
  }, []);

  useEffect(() => {
    if (match?.params.companyId && state.refetchAll) {
      const storagedToken = localStorage.getItem(authConfig.storageTokenKeyName);
      const params = new URLSearchParams(document.location.search.substring(1));
      getCustomerCompanyInfoAction(dispatch, Number(match.params.companyId), { userId: params.get('userId') || undefined, token: storagedToken || undefined });

      // Removing params
      window.history.pushState({}, document.title, window.location.pathname);
      dispatch(setRefetchAllAction(false));
    } else if (!match?.params.companyId) {
      dispatch({ type: AppStateEnum.SET_ERROR, error: { message: 'É necessário fornecer o id da empresa' } });
    }
  }, [match?.params.companyId, state.refetchAll]);

  return <AppContext.Provider value={[state, dispatch]}>{children}</AppContext.Provider>;
};

export const useAppState = () => {
  return useContext(AppContext);
};

export default AppContextProvider;
