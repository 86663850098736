/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import { CustomButtonProps } from '.';

export const Container = styled.div<CustomButtonProps>`
  button {
    width: 100%;
    ${props =>
    props.variant === 'outline' && `
      border-color: ${props.theme.colors.green[500]};
      font-size: 14px;
      transition: all 0.3s;
      background-color: ${props.selected ? props.theme.colors.green[500] : 'unset'};
      color: ${props.selected ? 'white' : props.theme.colors.green[500]} !important;
      :hover {
        background-color: ${props.theme.colors.green[500]};
        color: white !important;
      }
    `}

    :focus {
      box-shadow: 0 0 0 3px ${props => props.theme.colors.green[200]} !important;
    }
  }
`;
