import React from 'react';
import { useNavigate } from 'react-router-dom';
import Stripe from 'stripe';
import { BiPlus } from 'react-icons/bi';
import { FaEquals } from 'react-icons/fa';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box } from '@chakra-ui/react';
import Card from '../../molecules/Card';
import CurrentPlanPrice from '../../molecules/Card/Price/CurrentPlan';
import { Container } from './styles';
import { getFullUrl } from '../../../utils/main';
import FreePlan from '../../molecules/Card/Price/FreePlan';
import AdditionalMember from '../../molecules/Card/AdditionalMember';
import Total from '../../molecules/Card/Total';
import { useWindowDimensions } from '../../../hooks/use-window-dimensions';
import { SubscriptionStatus } from '../../../types/subscription';

interface ExpandedPlan extends Stripe.Plan {
  product: Stripe.Product;
}
interface ExpandedSubscription extends Stripe.Subscription {
  plan?: ExpandedPlan;
}

export interface CurrentPlanProps {
  subscription?: ExpandedSubscription;
  readOnly?: boolean;
}

const CurrentPlan: React.FC<CurrentPlanProps> = ({ subscription, readOnly }) => {
  const navigate = useNavigate();
  const { isLaptop } = useWindowDimensions();
  const isCanceling = subscription && subscription.status === SubscriptionStatus.CANCELED;
  if (isCanceling) {
    return (
      <Alert status='info'>
        <AlertIcon />
        <Box>
          <AlertTitle>Estamos cancelando seu plano</AlertTitle>
          <AlertDescription>Não se preocupe que em breve seu plano estará cancelado</AlertDescription>
        </Box>
        {/* <CloseButton alignSelf='flex-start' position='relative' right={-1} top={-1} onClick={onClose} /> */}
      </Alert>
    );
  }

  const product = subscription && !!subscription?.plan?.product && !!subscription?.plan?.product.metadata ? subscription?.plan?.product : undefined;
  const planPrice = subscription ? subscription?.items.data.find(item => item.price.metadata.type === 'plan')?.price : undefined;
  const additionalMemberPrice = subscription ? subscription?.items.data.find(item => item.price.metadata.type === 'additionalMember') : undefined;

  return subscription ? (
    <Container>
      <div>
        <Card readOnly={readOnly} component={<CurrentPlanPrice data={planPrice!} product={product} />} buttonText='Mudar plano' onClickButton={() => navigate(getFullUrl('planos-disponiveis'))} />
        {additionalMemberPrice && (
          <>
            <BiPlus />
            <Card readOnly component={<AdditionalMember data={additionalMemberPrice!} />} buttonText='Mudar' onClickButton={() => navigate(getFullUrl('planos-disponiveis'))} />
          </>
        )}
      </div>
      {additionalMemberPrice && (
        <>
          <FaEquals rotate={isLaptop ? '90deg' : 0} />
          <Card readOnly component={<Total data={subscription!} />} buttonText='Mudar plano' onClickButton={() => navigate(getFullUrl('planos-disponiveis'))} />
        </>
      )}
    </Container>
  ) : (
    <Container>
      <Card readOnly={readOnly} component={<FreePlan />} buttonText='Mudar plano' onClickButton={() => navigate(getFullUrl('planos-disponiveis'))} />
    </Container>
  );
};

export default CurrentPlan;
