import Stripe from 'stripe';
import api from '../services/api';

export const getCustomerCards = async (customerId: string) => {
  const response = await api.get<Stripe.Card[]>(`/customers/${customerId}/cards`);
  return response.data;
};

export const getCustomerUpcomingInvoice = async (customerId: string) => {
  const response = await api.get(`/customers/${customerId}/upcoming`);
  return response.data;
};

export const getCustomerSubscription = async (customerId: string) => {
  const response = await api.get(`/customers/${customerId}/subscription`);
  return response.data;
};

export const getCustomerSubscriptionSchedule = async (customerId: string) => {
  const response = await api.get(`/customers/${customerId}/subscription-schedule`);
  return response.data;
};

export const getCustomerAdditionalInfo = (customerId: string): Promise<[Stripe.Subscription, Stripe.Invoice, Stripe.Card[], Stripe.SubscriptionSchedule]> => {
  return Promise.all([getCustomerSubscription(customerId), getCustomerUpcomingInvoice(customerId), getCustomerCards(customerId), getCustomerSubscriptionSchedule(customerId)]);
};
