import { Skeleton } from '@chakra-ui/react';
import React from 'react';
import { Company } from '../../../types/company';

import { Container, CompanyInfoItem } from './styles';

export interface CompanyInfoProps {
  company?: Company;
  customer: any;
}

const CompanyInfoSkeleton = () => {
  return (
    <Container>
      <CompanyInfoItem>
        <span>
          <Skeleton height='20px' width='100px' />
        </span>
        <span>
          <Skeleton height='20px' width='100px' />
        </span>
      </CompanyInfoItem>
      <CompanyInfoItem>
        <span>
          <Skeleton height='20px' width='100px' />
        </span>
        <span>
          <Skeleton height='20px' width='100px' />
        </span>
      </CompanyInfoItem>
      <CompanyInfoItem>
        <span>
          <Skeleton height='20px' width='100px' />
        </span>
        <span>
          <Skeleton height='20px' width='100px' />
        </span>
      </CompanyInfoItem>
    </Container>
  );
};

const CompanyInfo: React.FC<CompanyInfoProps> = ({ company, customer }) => {
  return !company ? (
    <CompanyInfoSkeleton />
  ) : (
    <Container>
      <CompanyInfoItem>
        <span>Nome</span>
        <span>{company?.name}</span>
      </CompanyInfoItem>
      <CompanyInfoItem>
        <span>Razão social</span>
        <span>{customer.metadata.name}</span>
      </CompanyInfoItem>
      <CompanyInfoItem>
        <span>CNPJ</span>
        <span>{customer.metadata.document}</span>
      </CompanyInfoItem>
      <CompanyInfoItem>
        <span>Endereço</span>
        <span>
          {customer.metadata.street}, {customer.metadata.number}, {customer.metadata.neighborhood}, {customer.metadata.city}, {customer.metadata.state}, {customer.metadata.postal_code}
        </span>
      </CompanyInfoItem>
      <CompanyInfoItem>
        <span>Email de faturamento</span>
        <span>{customer.email}</span>
      </CompanyInfoItem>
    </Container>
  );
};

export default CompanyInfo;
