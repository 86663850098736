import { Button, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useNumberInput } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

export const BodyContent = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing.lg} 0;
  justify-content: center;

  input {
    text-align: center;
  }
`;

export const ButtonActions = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${({ theme }) => theme.spacing.default};
`;

export interface QuantityModalProps<T = any> {
  open: boolean;
  value: number;
  title?: string;
  item?: T;
  okText?: string;
  onClose: () => void;
  onSubmit: (item: T | undefined, value: number) => void;
  onChange?: (oldValue: number, value: number) => void;
  disableIf?: (value: number) => boolean;
}

function QuantityModal<T = any>({ open, onClose, value, onSubmit, title = 'Atualizar', okText = 'Atualizar', item, onChange, disableIf }: QuantityModalProps<T>) {
  const [localValue, setLocalValue] = useState(value);
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step: 1,
    defaultValue: 0,
    min: 0,
    precision: 0,
    value: localValue,
  });

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleClose = () => {
    onClose();
  };

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();
  return (
    <Modal onClose={handleClose} isOpen={open} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <BodyContent>
            <HStack maxW='170px'>
              <Button
                {...dec}
                onClick={() =>
                  setLocalValue(prev => {
                    const newValue = prev - 1;
                    if (onChange) onChange(value, newValue);
                    return newValue;
                  })
                }
              >
                -
              </Button>
              <Input {...input} />
              <Button
                {...inc}
                onClick={() =>
                  setLocalValue(prev => {
                    const newValue = prev + 1;
                    if (onChange) onChange(value, newValue);
                    return newValue;
                  })
                }
              >
                +
              </Button>
            </HStack>
          </BodyContent>
        </ModalBody>
        <ModalFooter>
          <ButtonActions>
            <Button onClick={handleClose}>Fechar</Button>
            <Button
              disabled={disableIf ? disableIf(localValue) || value === localValue : value === localValue}
              backgroundColor='green.500'
              color='white'
              _hover={{ backgroundColor: 'green.600' }}
              onClick={() => onSubmit(item, localValue)}
            >
              {okText}
            </Button>
          </ButtonActions>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default QuantityModal;
