import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, AlertIcon, Button, Spinner, Table, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { MdOutlineExpandMore } from 'react-icons/md';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import Stripe from 'stripe';
import { Column, ColumnCard, ColumnCardContent, ColumnCardHeader, ColumnCardTitle, Container, SubscriptionItem, SelectQuantity, NextSteps } from './styles';
import CreateCardForm, { CreateCardFormProps } from '../../../components/organisms/Forms/CreateCard';
import { useAppState } from '../../../context/app';
import PaymentCard from '../../../components/molecules/PaymentCard';
import CardsDialog, { CardsDialogProps } from '../../../components/organisms/CardsDialog';
import { addNewCardAction } from '../../../context/app/actions';
import { SubscriptionItemType, useCheckoutData } from '..';
import QuantityModal, { QuantityModalProps } from '../../../components/organisms/QuantityModal';
import { delay } from '../../../utils/main';
import theme from '../../../theme';
import CurrentPlanTable from '../../../components/organisms/CurrentPlanTable';
import { PriceMetadataType } from '../../../types/price';

export interface Step2Props {}

const defaultQuantityDialog = {
  open: false,
  value: 0,
};

type QuantityDialog = { open: boolean; title?: string; value: number; item?: SubscriptionItemType };

const Step2: React.FC<Step2Props> = () => {
  const [checkoutData, setCheckoutData] = useCheckoutData();
  const [state, dispatch] = useAppState();
  const [openCardDialog, setOpenCardDialog] = useState(false);
  const [quantityDialog, setQuantityDialog] = useState<QuantityDialog>(defaultQuantityDialog);
  const isPlanChanging = useMemo(() => {
    const oldPlanItem = state.customer?.subscription ? state.customer?.subscription?.items.data.find(item => item.price.metadata.type === PriceMetadataType.PLAN) : undefined;
    const newPlanItem = checkoutData.subscriptionItems.find(item => item.metadata.type === PriceMetadataType.PLAN);
    if (!oldPlanItem) return true;
    return oldPlanItem?.price.id !== newPlanItem?.id;
  }, [checkoutData.subscriptionItems, state.customer?.subscription]);

  const customerAdditionalSubscriptionItem = useMemo(
    () => (state.customer?.subscription ? state.customer.subscription.items.data.find(item => item.price.metadata.type === PriceMetadataType.ADDITIONAL_MEMBER) : undefined),
    [state.customer?.subscription]
  );
  const subscriptionItemsTotal = useMemo(() => {
    const total = checkoutData.subscriptionItems.reduce((acc, item) => {
      if (item.readonly || item.hide) return acc;
      return acc + item.quantity * item.unitPrice;
    }, 0);
    return total;
  }, [checkoutData.subscriptionItems]);

  const cartTotal = useMemo(() => {
    const total = checkoutData.cart.reduce((acc, item) => {
      if (item.readonly || item.hide) return acc;
      return acc + item.quantity * item.unitPrice;
    }, 0);
    const discount = checkoutData.discount || 0;
    return total - discount;
  }, [checkoutData.discount, checkoutData.cart]);

  const setSelectedCard = useCallback(
    (card: Stripe.Card | undefined) => {
      setCheckoutData(prev => ({ ...prev, selectedCard: card }));
    },
    [setCheckoutData]
  );

  const handleChangeCardData: CreateCardFormProps['onChange'] = (field, value) => {
    setCheckoutData(prev => ({ ...prev, firstCard: { ...prev.firstCard, data: { ...prev.firstCard.data, [field]: value } } }));
  };

  const handleCloseCardsDialog = () => {
    setOpenCardDialog(false);
  };

  const handleSubmitSelectedCard = (card: Stripe.Card | undefined) => {
    setSelectedCard(card);
    setOpenCardDialog(false);
  };

  const handleAddNewCard: CardsDialogProps['onAddNewCard'] = async data => {
    await delay(1000);
    const response = await addNewCardAction(dispatch, state.customer.data?.id!, data);
    return response;
  };

  const handleQuantityClick = (item: SubscriptionItemType) => () => {
    setQuantityDialog({
      item,
      open: true,
      title: 'Atualizar Quantidade',
      value: item.quantity,
    });
  };
  // useEffect(() => {
  //   const res = api.post(
  //     `/customers/${state.customer?.data.id}/subscription/preview`,
  //     checkoutData.subscriptionItems
  //       .filter(item => item.quantity !== 0)
  //       .map(item => ({
  //         price: item.id,
  //         quantity: item.quantity,
  //         type: item.type,
  //       }))
  //   );
  // }, [checkoutData.subscriptionItems, state.customer?.data.id]);

  const handleSubmitSelectQuantity: QuantityModalProps<SubscriptionItemType>['onSubmit'] = (item, value) => {
    if (!item) return;
    if (checkoutData.overrideOnChangeQuantity) {
      checkoutData.overrideOnChangeQuantity(checkoutData.subscriptionItems, item, value);
      setQuantityDialog(defaultQuantityDialog);
      return;
    }
    setCheckoutData(prev => {
      return {
        ...prev,
        subscriptionItems: prev.subscriptionItems.map(subscriptionItem => (subscriptionItem.metadata.type === item.metadata.type ? { ...subscriptionItem, quantity: value } : subscriptionItem)),
      };
    });
    setQuantityDialog(defaultQuantityDialog);
  };

  useEffect(() => {
    const defaultCard = state.customer?.cards.find(card => card.id === state.customer?.data?.default_source);
    setSelectedCard(defaultCard);
  }, [setSelectedCard, state.customer]);

  return (
    <Container>
      <Column>
        <ColumnCard>
          <ColumnCardTitle>Assinatura atual</ColumnCardTitle>
          <ColumnCardContent>
            <CurrentPlanTable subscription={state.customer?.subscription} />
          </ColumnCardContent>
        </ColumnCard>
        <ColumnCard>
          <ColumnCardTitle>Assinatura nova</ColumnCardTitle>
          <ColumnCardContent>
            {checkoutData.loadingItems && <Spinner color='green.500' />}
            {!checkoutData.loadingItems && (
              <TableContainer>
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th colSpan={2}>Item</Th>
                      <Th isNumeric>Preço</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {checkoutData.subscriptionItems
                      .filter(item => !item.hide)
                      .map(item => (
                        <Tr key={item.id} opacity={item.readonly ? 0.3 : 1} title={item.readonly ? 'Este item não será alterado' : undefined}>
                          <Td colSpan={2}>
                            <SubscriptionItem>
                              <div>
                                <span>{item.name}</span>
                                {item.selectQuantity ? (
                                  <SelectQuantity>
                                    <span>Quantidade: </span>
                                    <Button variant='ghost' onClick={handleQuantityClick(item)}>
                                      {item.quantity} <MdOutlineExpandMore />
                                    </Button>
                                  </SelectQuantity>
                                ) : (
                                  <span>{item.detail}</span>
                                )}
                              </div>
                            </SubscriptionItem>
                          </Td>
                          <Td isNumeric>R$ {(item.quantity * item.unitPrice).toFixed(2).replace('.', ',')}</Td>
                        </Tr>
                      ))}
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <Th colSpan={2}>Total</Th>
                      <Th isNumeric>R$ {subscriptionItemsTotal.toFixed(2).replace('.', ',')}</Th>
                    </Tr>
                  </Tfoot>
                </Table>
              </TableContainer>
            )}
          </ColumnCardContent>
        </ColumnCard>
        {!checkoutData.isDowngrade && checkoutData.cart.length > 0 && (
          <ColumnCard>
            <ColumnCardTitle>Carrinho</ColumnCardTitle>
            <ColumnCardContent>
              {checkoutData.loadingItems && <Spinner color='green.500' />}
              {!checkoutData.loadingItems && (
                <TableContainer>
                  <Table variant='simple'>
                    <Thead>
                      <Tr>
                        <Th colSpan={2}>Item</Th>
                        <Th isNumeric>Preço</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {checkoutData.cart
                        .filter(item => !item.hide)
                        .map(item => (
                          <Tr key={item.id} opacity={item.readonly ? 0.3 : 1} title={item.readonly ? 'Este item não será alterado' : undefined}>
                            <Td colSpan={2}>
                              <SubscriptionItem>
                                <div>
                                  <span>{item.name}</span>
                                  <span>Quantidade: {item.quantity}</span>
                                </div>
                              </SubscriptionItem>
                            </Td>
                            <Td isNumeric>R$ {(item.quantity * item.unitPrice).toFixed(2).replace('.', ',')}</Td>
                          </Tr>
                        ))}
                    </Tbody>
                    <Tfoot>
                      {!!checkoutData.discount && (
                        <Tr className='discount'>
                          <Th colSpan={2}>
                            Desconto
                            <Tooltip hasArrow label={checkoutData.discountMessage}>
                              <span>
                                <BsFillInfoCircleFill />
                              </span>
                            </Tooltip>
                          </Th>
                          <Th isNumeric>R$ {checkoutData.discount.toFixed(2).replace('.', ',')}</Th>
                        </Tr>
                      )}
                      <Tr className='total'>
                        <Th colSpan={2}>Total</Th>
                        <Th isNumeric>R$ {cartTotal.toFixed(2).replace('.', ',')}</Th>
                      </Tr>
                    </Tfoot>
                  </Table>
                </TableContainer>
              )}
            </ColumnCardContent>
          </ColumnCard>
        )}
        {!checkoutData.isDowngrade && (
          <ColumnCard>
            <ColumnCardHeader>
              <ColumnCardTitle>Pagamento</ColumnCardTitle>
              {checkoutData.selectedCard && !checkoutData.cardReadOnly && <p onClick={() => setOpenCardDialog(true)}>Editar</p>}
            </ColumnCardHeader>
            <ColumnCardContent>
              {checkoutData.selectedCard ? (
                <PaymentCard selected data={checkoutData.selectedCard} />
              ) : (
                <CreateCardForm onChange={handleChangeCardData} errors={checkoutData.firstCard.errors} data={checkoutData.firstCard.data} />
              )}
            </ColumnCardContent>
          </ColumnCard>
        )}
        {checkoutData.infos &&
          checkoutData.infos.map(info => (
            <Alert key={info} borderRadius={theme.style['border-radius'].default} status='info'>
              <AlertIcon />
              {info}
            </Alert>
          ))}
        {!checkoutData.loadingItems && checkoutData.nextSteps && checkoutData.nextSteps?.length > 0 && (
          <NextSteps>
            <h2>O que acontece agora?</h2>
            <ul>
              {checkoutData.nextSteps?.map(step => (
                <li key={step}>{step}</li>
              ))}
            </ul>
          </NextSteps>
        )}
      </Column>
      <CardsDialog
        onSubmitSelectedCard={handleSubmitSelectedCard}
        onAddNewCard={handleAddNewCard}
        selectedCard={checkoutData.selectedCard}
        cards={state.customer!.cards}
        open={openCardDialog}
        onClose={handleCloseCardsDialog}
      />
      <QuantityModal<SubscriptionItemType>
        {...quantityDialog}
        onSubmit={handleSubmitSelectQuantity}
        disableIf={value => (isPlanChanging ? false : value === customerAdditionalSubscriptionItem?.quantity)}
        onClose={() => setQuantityDialog(prev => ({ ...prev, open: false }))}
      />
    </Container>
  );
};

export default Step2;
