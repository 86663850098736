export enum AppStateEnum {
  START_LOADING = 'START_LOADING',
  SET_ERROR = 'SET_ERROR',
  STOP_LOADING = 'STOP_LOADING',
  SET_COMPANY_CUSTOMER = 'SET_COMPANY_CUSTOMER',
  ADD_NEW_CARD = 'ADD_NEW_CARD',
  REMOVE_CARD = 'REMOVE_CARD',
  SET_APP_STATE = 'SET_APP_STATE',
  SET_REFETCH_ALL = 'SET_REFETCH_ALL',
  SET_CUSTOMER_ADDITIONAL_INFO = 'SET_CUSTOMER_ADDITIONAL_INFO',
  CANCEL_PLAN = 'CANCEL_PLAN',
}
