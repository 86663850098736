import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, ButtonProps } from '@chakra-ui/react';
import React, { useState } from 'react';

// import { Container } from './styles';

export interface ConfirmationModalProps {
  okButtonProps?: ButtonProps;
  open: boolean;
  title: string;
  text: string;
  okText?: string;
  closeText?: string;
  hideCancel?: boolean;
  onOk: (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => void | Promise<void>;
  onClose: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ onClose, onOk, open, text, title, okText = 'Sim', closeText = 'Não', hideCancel, okButtonProps }) => {
  const [loading, setLoading] = useState(false);
  const handleOk = async () => {
    const onOkResponse = onOk(setLoading);
    if (onOkResponse !== undefined) {
      setLoading(true);
      await Promise.resolve(onOkResponse);
      setLoading(false);
    }
    onClose();
  };

  return (
    <AlertDialog motionPreset='slideInBottom' leastDestructiveRef={undefined} onClose={onClose} isOpen={open} isCentered>
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>{title}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </AlertDialogBody>
        <AlertDialogFooter>
          {!hideCancel && (
            <Button disabled={loading} onClick={onClose}>
              {closeText}
            </Button>
          )}
          <Button disabled={loading} isLoading={loading} colorScheme='blue' ml={3} onClick={handleOk} {...okButtonProps}>
            {okText}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ConfirmationModal;
