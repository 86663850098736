import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';

import { Container } from './styles';

export interface CustomButtonProps extends ButtonProps {
  selected?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({ children, selected, onClick, ...props }) => {
  return (
    <Container selected={selected} {...props}>
      <Button {...props} onClick={onClick || undefined}>
        {children}
      </Button>
    </Container>
  );
};

export default CustomButton;
