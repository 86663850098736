import { CanceledError } from 'axios';
import Stripe from 'stripe';
import { Company } from '../../types/company';
import api from '../../services/api';
import { Card } from '../../types/card';
import { delay } from '../../utils/main';
import { Dispatcher } from '../types';
import { AppStateEnum } from './enum';
import { AppState } from '.';
import { getCustomerAdditionalInfo } from '../../utils/customer';
import { isAxiosError } from '../../utils/axios';

// Async actions
export const getCustomerCompanyInfoAction = async (dispatch: Dispatcher<AppStateEnum>, companyId: Company['id'], params?: { userId?: string; token?: string }) => {
  try {
    // Delaying to a more real experience
    await delay(2000);
    const res = await api.get(`/customers/${companyId}`, { params });
    if (res.data?.customer) {
      const [subscription, upcoming, cards, subscriptionSchedule] = await getCustomerAdditionalInfo(res.data.customer.id);
      dispatch({ type: AppStateEnum.SET_APP_STATE, payload: { customer: { data: res.data.customer, subscription, upcoming, cards, subscriptionSchedule }, company: res.data.company } });
    }
  } catch (error) {
    if (error instanceof CanceledError) return;
    if (isAxiosError(error) && error.response?.data.message) {
      dispatch({ type: AppStateEnum.SET_ERROR, error: error.response?.data });
    } else {
      dispatch({ type: AppStateEnum.SET_ERROR, error });
    }
  }
};

export const addNewCard = async (customerId: Stripe.Customer['id'], cardData: Card) => {
  const addedCard = await api.post<Stripe.Card>(`/customers/${customerId}/card`, cardData);
  return addedCard.data;
};

export const addNewCardAction = async (dispatch: Dispatcher<AppStateEnum>, customerId: Stripe.Customer['id'], cardData: Card) => {
  // try {
  const addedCard = await addNewCard(customerId, cardData);
  dispatch({ type: AppStateEnum.ADD_NEW_CARD, payload: addedCard });
  return addedCard;
  // } catch (error) {
  //   if (error instanceof CanceledError) return;
  //   dispatch({ type: AppStateEnum.SET_ERROR, error });
  // }
};

export const changeSubscriptionCardAction = async (dispatch: Dispatcher<AppStateEnum>, subscriptionId: Stripe.Subscription['id'], cardId: Stripe.Card['id']) => {
  const result = await api.patch<Stripe.Subscription>(`/subscriptions/${subscriptionId}/payment-method`, {
    cardId,
  });
  dispatch(
    setCustomerAdditionalInfoAction(prev => {
      if (prev?.subscription?.default_source) {
        return { ...prev, subscription: { ...prev.subscription, default_source: result.data.default_source } };
      }
      if (prev?.subscription?.default_payment_method) {
        return { ...prev, subscription: { ...prev.subscription, default_payment_method: result.data.default_payment_method } };
      }
      return prev;
    })
  );
};

export const removeCardAction = async (dispatch: Dispatcher<AppStateEnum>, cardId: Stripe.Card['id']) => {
  try {
    await api.delete(`/customers/card/${cardId}/detach`);
    dispatch({ type: AppStateEnum.REMOVE_CARD, payload: { cardId } });
  } catch (error) {
    dispatch({ type: AppStateEnum.SET_ERROR, error });
  }
};

export const cancelPlanAction = async (dispatch: Dispatcher<AppStateEnum>, customerId: Stripe.Customer['id'], subscriptionId: Stripe.Subscription['id']) => {
  try {
    const { data: subscription } = await api.delete(`/subscriptions/${subscriptionId}`);
    dispatch({ type: AppStateEnum.CANCEL_PLAN, payload: { subscription } });
  } catch (error) {
    dispatch({ type: AppStateEnum.SET_ERROR, error });
  }
};

// Sync actions
export const setRefetchAllAction = (payload: AppState['refetchAll']) => ({
  type: AppStateEnum.SET_REFETCH_ALL,
  payload,
});

export const setCustomerAdditionalInfoAction = (payload: Partial<Omit<AppState['customer'], 'data'>> | ((state: AppState['customer']) => AppState['customer'])) => ({
  type: AppStateEnum.SET_CUSTOMER_ADDITIONAL_INFO,
  payload,
});
