import React from 'react';
import Stripe from 'stripe';
import { parseStripeValueToPrice } from '../../../../utils/stripe';
import { Content, PriceName, PriceMembers, PriceCurrency, PriceValue, PriceInstallmentPrice } from './styles';

const AdditionalMember: React.FC<{ data: Stripe.SubscriptionItem }> = ({ data }) => {
  return (
    <Content>
      <PriceName>
        <strong>{data?.quantity}</strong> {data?.quantity! > 1 ? 'Profissionais Adicionais' : 'Profissional Adicional'}
      </PriceName>
      <PriceMembers>Valor unitário: R$ {parseStripeValueToPrice(data.price.unit_amount!)}</PriceMembers>
      <PriceInstallmentPrice>
        <div>
          <PriceCurrency>R$</PriceCurrency>
        </div>
        <PriceValue>{parseStripeValueToPrice(data.quantity! * data.price.unit_amount!)}</PriceValue>
      </PriceInstallmentPrice>
    </Content>
  );
};

export default AdditionalMember;
