import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > svg {
    margin: 0 ${({ theme }) => theme.spacing.default};
    ${props => props.theme.medias.sm} {
      margin: ${({ theme }) => theme.spacing.default} 0;
      transform: rotate(90deg);
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > svg {
      margin: ${({ theme }) => theme.spacing.default} 0;
    }
  }

  ${props => props.theme.medias.sm} {
    width: 100%;
    flex-direction: column;
  }
`;
