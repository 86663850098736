import { Skeleton } from '@chakra-ui/react';
import React from 'react';
import CustomButton from '../../atoms/Button';

import { Container, ActionWrapper } from './styles';

export interface CardProps {
  component: React.ReactNode;
  buttonText?: string;
  loading?: boolean;
  selected?: boolean;
  readOnly?: boolean;
  clickable?: boolean;
  disable?: boolean;
  onClickButton?: () => void;
  onClick?: () => void;
  buttonDisable?: boolean;
}

const Card: React.FC<CardProps> = ({ buttonDisable, readOnly, component, buttonText, loading, onClickButton, onClick, selected, clickable, disable }) => {
  return (
    <Container onClick={clickable && onClick ? onClick : undefined} clickable={clickable} disable={disable}>
      {component}
      {buttonText && !readOnly && (
        <ActionWrapper>
          <CustomButton
            selected={selected}
            onClick={
              onClickButton
                ? e => {
                    onClickButton();
                    e.stopPropagation();
                  }
                : undefined
            }
            disabled={loading || buttonDisable}
            variant='outline'
          >
            {loading ? <Skeleton width='50px' height='10px' /> : buttonText}
          </CustomButton>
        </ActionWrapper>
      )}
    </Container>
  );
};

export default Card;
