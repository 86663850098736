import { Badge, Box, Button, Menu, MenuButton, MenuItem, MenuList, Stack, useToast } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { AiFillClockCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Stripe from 'stripe';
import { differenceInDays } from 'date-fns';
import { BiChevronDown } from 'react-icons/bi';
import CompanyInfo from '../../components/molecules/CompanyInfo';
import ConfirmationModal, { ConfirmationModalProps } from '../../components/molecules/ConfirmationModal';
import PaymentCard from '../../components/molecules/PaymentCard';
import CurrentPlan from '../../components/organisms/CurrentPlan';
import { useAppState } from '../../context/app';
import { addNewCardAction, cancelPlanAction, changeSubscriptionCardAction, setCustomerAdditionalInfoAction } from '../../context/app/actions';
import theme from '../../theme';
import { SubscriptionStatus } from '../../types/subscription';
import { getCustomerCardFromPaymentMethod, getCustomerCardFromSource } from '../../utils/card';
import { getDateByMs } from '../../utils/date';
import { delay, getFullUrl } from '../../utils/main';
import { parseStripeValueToPrice } from '../../utils/stripe';
import { Container, Section, PaymentSectionContentWrapper, SectionTitle } from './styles';
import { checkIsTrial } from '../../utils/company';
import api from '../../services/api';
import { PriceMetadataType } from '../../types/price';
import QuantityModal, { QuantityModalProps } from '../../components/organisms/QuantityModal';
import CardsDialog, { CardsDialogProps } from '../../components/organisms/CardsDialog';
import { getCustomerUpcomingInvoice } from '../../utils/customer';

const defaultCancelDialogState = {
  open: false,
  title: '',
  text: '',
  onClose: () => {},
  onOk: () => {},
};

const defaultQuantityDialog: QuantityModalProps = {
  open: false,
  value: 0,
  onClose: () => {},
  onSubmit: () => {},
};

const HomePage: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [appState, dispatch] = useAppState();
  const [quantityDialog, setQuantityDialog] = useState<QuantityModalProps>(defaultQuantityDialog);
  const [openCardDialog, setOpenCardDialog] = useState<boolean>(false);
  const [cancelDialogState, setCancelDialogState] = useState<ConfirmationModalProps>(defaultCancelDialogState);
  const isCanceling = appState?.customer?.subscription && appState.customer.subscription.status === SubscriptionStatus.CANCELED;
  const cancelDate = appState?.customer?.subscription && appState?.customer?.subscription?.cancel_at_period_end ? getDateByMs(appState.customer.subscription.current_period_end) : undefined;
  const willStart = !!appState.customer?.subscriptionSchedule && appState.customer?.subscriptionSchedule.status === 'not_started';
  const willUpdate = !!appState.customer?.subscriptionSchedule && appState.customer?.subscriptionSchedule.status === 'active';
  // When is past due, an subscription schedule is returned (willUpdate === true), we need to ignore it
  const isPastDue = useMemo(() => appState?.customer!.subscription!.status === SubscriptionStatus.PAST_DUE, [appState?.customer]);
  const showPaymentSection = (appState.customer?.subscription && !isCanceling && !cancelDate) || willStart;
  const isTrial = useMemo(() => checkIsTrial(appState.company), [appState.company]);
  const customerCard = useMemo(() => {
    if (willStart) {
      return getCustomerCardFromPaymentMethod(appState.customer?.subscriptionSchedule?.phases[0].default_payment_method as Stripe.PaymentMethod);
    }
    if (appState.customer?.subscription?.default_source) {
      return getCustomerCardFromSource(appState.customer?.subscription?.default_source as Stripe.CustomerSource);
    }
    if (appState.customer?.subscription?.default_payment_method) {
      return getCustomerCardFromPaymentMethod(appState.customer?.subscription?.default_payment_method as Stripe.PaymentMethod);
    }
  }, [appState.customer?.subscription?.default_payment_method, appState.customer?.subscription?.default_source, appState.customer?.subscriptionSchedule?.phases, willStart]);

  const WarnText = () => {
    if (willStart) {
      const value = appState.customer?.subscriptionSchedule?.phases[0].items.reduce((sum, item) => {
        const price = item.price as Stripe.Price;
        if (price.metadata.type === PriceMetadataType.PLAN) {
          return sum + price.unit_amount!;
        }
        return sum + price.unit_amount! * item.quantity!;
      }, 0);
      return (
        <>
          Seu assinatura inicia dia <b>{getDateByMs(appState.customer?.subscriptionSchedule!.phases[0].start_date!)}</b>, será debitado no seu cartão o valor de{' '}
          <b>R${parseStripeValueToPrice(value!)}</b>.
        </>
      );
    }
    if (appState?.customer!.subscription!.status === SubscriptionStatus.ACTIVE) {
      return (
        <>
          Sua próxima cobrança será no dia <b>{getDateByMs(appState.customer.upcoming!.next_payment_attempt!)}</b>, no valor de <b>R${parseStripeValueToPrice(appState?.customer?.upcoming!.total)}</b>.
        </>
      );
    }
    if (isPastDue) {
      const latestInvoice = appState?.customer!.subscription!.latest_invoice as Stripe.Invoice;
      return (
        <>
          O seu <b style={{ color: theme.colors.red[400] }}>pagamento no valor de R${parseStripeValueToPrice(latestInvoice.amount_due)} falhou</b> realizaremos uma nova tentativa na data{' '}
          {getDateByMs(latestInvoice.next_payment_attempt!)} com o cartão abaixo, você pode mudá-lo ou{' '}
          <a style={{ color: theme.colors.green[500], textDecoration: 'underline', cursor: 'pointer' }} onClick={() => navigate(`/${appState.company?.id}/fatura/${latestInvoice.id}`)}>
            pagar manualmente clicando aqui
          </a>
        </>
      );
    }
    if (appState?.customer!.subscription!.status === SubscriptionStatus.TRIALING) {
      return (
        <>
          Seu período de teste se encerra no dia <b>{getDateByMs(appState.customer.upcoming!.next_payment_attempt!)}</b>, será debitado no seu cartão o valor de{' '}
          <b>R${parseStripeValueToPrice(appState?.customer?.upcoming!.total)}</b>.
        </>
      );
    }
    return <></>;
  };

  const PlanBadge = () => {
    let badges = [
      <Badge key={1} colorScheme='green'>
        Ativo
      </Badge>,
    ];
    const daysLeft = differenceInDays(new Date(appState?.company?.trial_end_at!), new Date());
    if (isTrial) {
      badges = [
        <Badge key={1} colorScheme='blue'>
          Período de teste ({daysLeft === 0 ? '1 dia restante' : `${daysLeft} dias restantes`})
        </Badge>,
      ];
    }
    if (willUpdate && !isPastDue) {
      badges.push(
        <Badge key={2} display='flex' alignItems='center' colorScheme='facebook'>
          <Box mr={theme.spacing.xxs}>
            <AiFillClockCircle size={14} />
          </Box>
          Atualização agendada para {getDateByMs(appState.customer?.subscriptionSchedule!.current_phase?.end_date)}
        </Badge>
      );
    }
    if (willStart && appState.customer?.subscriptionSchedule!.phases[0].start_date) {
      badges.push(
        <Badge key={2} display='flex' alignItems='center' colorScheme='facebook'>
          <Box mr={theme.spacing.xxs}>
            <AiFillClockCircle size={14} />
          </Box>
          Assinatura agendada para {getDateByMs(appState.customer?.subscriptionSchedule!.phases[0].start_date)}
        </Badge>
      );
    }
    if (cancelDate) {
      badges.push(
        <Badge key={3} display='flex' alignItems='center' colorScheme='facebook'>
          <Box mr={theme.spacing.xxs}>
            <AiFillClockCircle size={14} />
          </Box>
          Cancela em {cancelDate}
        </Badge>
      );
    }
    if (isCanceling) return <></>;
    return badges.length > 1 ? <Stack direction='row'>{badges}</Stack> : badges[0];
  };

  const handleOpenCancelDialog = () => {
    setCancelDialogState(prev => ({
      ...prev,
      open: true,
      title: 'Tem certeza?',
      text:
        appState.customer!.subscription!.status === SubscriptionStatus.TRIALING
          ? 'Essa ação é irreversível e irá cancelar seu período de teste. Lembramos que não será possível ter outro período de teste. Tem certeza que quer continuar?'
          : 'Essa ação é irreversível e irá cancelar seu plano e profissionais adicionais, caso tenha. Seu plano continuará disponível até o fim do período. Tem certeza que quer continuar?',
      onClose: () => setCancelDialogState(defaultCancelDialogState),
      onOk: async () => {
        try {
          await cancelPlanAction(dispatch, appState.customer.data?.id!, appState.customer!.subscription!.id);
        } catch (error) {
          toast({
            status: 'error',
            isClosable: true,
            title: 'Ocorreu um erro cancelar plano, tente novamente mais tarde',
          });
        }
      },
    }));
  };

  const handleCancelSchedule = async () => {
    let text: string;
    let handler: () => Promise<void>;
    if (cancelDate) {
      text = 'Essa ação é irreversível e irá cancelar o agendamento de cancelamento da assinatura. Tem certeza que quer continuar?';
      handler = async () => {
        await api.patch(`/subscriptions/${appState.customer?.subscription!.id}/dont-cancel`);
        const upcoming = await getCustomerUpcomingInvoice(appState.customer.data?.id!);
        dispatch(setCustomerAdditionalInfoAction(prev => ({ ...prev, subscription: { ...prev.subscription!, cancel_at_period_end: false }, upcoming })));
      };
    } else if (willStart) {
      text = 'Essa ação é irreversível e irá cancelar o agendamento da assinatura. Tem certeza que quer continuar?';
      handler = async () => {
        await api.delete(`/subscriptions/schedule/${appState.customer?.subscriptionSchedule?.id}/cancel`);
        dispatch(setCustomerAdditionalInfoAction({ subscriptionSchedule: undefined }));
      };
    } else if (willUpdate) {
      text = 'Essa ação é irreversível e irá cancelar o agendamento de atualização da assinatura. Tem certeza que quer continuar?';
      handler = async () => {
        await api.patch(`/subscriptions/schedule/${appState.customer?.subscriptionSchedule?.id}/release`);
        dispatch(setCustomerAdditionalInfoAction({ subscriptionSchedule: undefined }));
      };
    }
    setCancelDialogState(prev => ({
      ...prev,
      open: true,
      title: 'Tem certeza?',
      text,
      onClose: () => setCancelDialogState(defaultCancelDialogState),
      onOk: async setLoading => {
        try {
          setLoading(true);
          await handler();
          toast({
            status: 'success',
            isClosable: true,
            title: 'Agendamento cancelado com sucesso!',
          });
          setLoading(false);
        } catch (error) {
          setLoading(false);
          toast({
            status: 'error',
            isClosable: true,
            title: 'Ocorreu um erro cancelar agendamento, tente novamente mais tarde',
          });
        }
      },
    }));
  };

  const handleChangeAdditionalMembers = () => {
    const additionalItem = appState.customer?.subscription?.items.data.find(item => item.price.metadata.type === PriceMetadataType.ADDITIONAL_MEMBER);

    setQuantityDialog(prev => ({
      ...prev,
      open: true,
      title: 'Gerenciar membros adicionais',
      okText: 'Ir para carrinho',
      value: additionalItem ? additionalItem.quantity! : 0,
    }));
  };

  const handleSubmitAdditionalMembers: QuantityModalProps['onSubmit'] = async (_, value) => {
    const plan = appState.customer?.subscription!.items.data.find(item => item.price.metadata.type === PriceMetadataType.PLAN);
    navigate(`/${appState.company?.id}/trocar-plano?additional=${plan?.price.metadata.related}&step=2&quantity=${value}`);
  };

  const handleAddNewCard: CardsDialogProps['onAddNewCard'] = async data => {
    await delay(1000);
    await addNewCardAction(dispatch, appState.customer.data?.id!, data);
  };

  const handleChangeCard: CardsDialogProps['onSubmitSelectedCard'] = async card => {
    try {
      await changeSubscriptionCardAction(dispatch, appState.customer?.subscription?.id!, card.id);
      toast({
        status: 'success',
        isClosable: true,
        title: 'Cartão alterado com sucesso',
      });
    } catch (error) {
      toast({
        status: 'error',
        isClosable: true,
        title: 'Ocorreu um erro mudar cartão, tente novamente mais tarde',
      });
    }
  };

  return (
    <Container>
      <Section>
        <SectionTitle>
          <h2>Informações da empresa</h2>
        </SectionTitle>
        <CompanyInfo company={appState.company} customer={appState.customer.data} />
      </Section>
      <Section>
        <SectionTitle>
          <h2>Plano Atual</h2>
          <PlanBadge />
        </SectionTitle>
        <CurrentPlan readOnly subscription={appState?.customer?.subscription} />
        {!cancelDate && (!willUpdate || isPastDue) && !willStart && !isCanceling && (
          <Menu>
            <MenuButton
              disabled={isPastDue}
              title={isPastDue ? 'Pague a fatura em atraso para mudar seu plano' : undefined}
              onClick={!appState.customer?.subscription ? () => navigate(getFullUrl('planos-disponiveis')) : undefined}
              backgroundColor='green.400'
              color='white'
              _hover={{ backgroundColor: 'green.500', color: 'white' }}
              mr={theme.spacing.default}
              as={Button}
              rightIcon={appState.customer?.subscription ? <BiChevronDown /> : undefined}
            >
              Mudar
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => navigate(getFullUrl('planos-disponiveis'))}>Mudar Plano</MenuItem>
              <MenuItem onClick={handleChangeAdditionalMembers}>Gerenciar membros adicionais</MenuItem>
            </MenuList>
          </Menu>
        )}
        {appState?.customer?.subscription && !isCanceling && !cancelDate && !willUpdate && (
          <Button onClick={handleOpenCancelDialog} _hover={{ backgroundColor: 'red.600', color: 'white' }} variant='ghost' border='1px' color='red.500'>
            Cancelar
          </Button>
        )}
        {(willStart || cancelDate || (willUpdate && !isPastDue)) && (
          <Button onClick={handleCancelSchedule} _hover={{ backgroundColor: 'red.600', color: 'white' }} variant='ghost' border='1px' color='red.500'>
            Cancelar Agendamento
          </Button>
        )}
      </Section>
      {showPaymentSection && (
        <Section>
          <SectionTitle>
            <h2>Pagamento</h2>
          </SectionTitle>
          <PaymentSectionContentWrapper>
            {appState?.customer?.upcoming && (
              <span>
                <WarnText />
              </span>
            )}
            {!!customerCard && <PaymentCard data={customerCard} />}
            <Button
              maxWidth={110}
              mt={theme.spacing.default}
              onClick={() => setOpenCardDialog(true)}
              backgroundColor='green.400'
              color='white'
              _hover={{ backgroundColor: 'green.500', color: 'white' }}
              mr={theme.spacing.default}
              as={Button}
            >
              Mudar
            </Button>
          </PaymentSectionContentWrapper>
        </Section>
      )}
      <ConfirmationModal {...cancelDialogState} />
      <QuantityModal {...quantityDialog} onSubmit={handleSubmitAdditionalMembers} onClose={() => setQuantityDialog(prev => ({ ...prev, open: false }))} />
      <CardsDialog
        onSubmitSelectedCard={handleChangeCard}
        disableIf={currentSelectedCard => currentSelectedCard.id === customerCard?.id}
        onAddNewCard={handleAddNewCard}
        selectedCard={customerCard}
        cards={appState.customer?.cards || []}
        open={openCardDialog}
        onClose={() => setOpenCardDialog(false)}
      />
    </Container>
  );
};

export default HomePage;
