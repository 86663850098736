import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100%;
    width: 100vw;
  }

  h1 {
    font-size: ${props => props.theme.font.size.xxl} !important;
    font-weight: 700 !important;
  }

  h2 {
    font-size: ${props => props.theme.font.size.xl} !important;
    font-weight: 600 !important;
  }

  body, p, input, span, h1, h2, h3, h4, h5, h6, div, th  {
    font-family: 'Poppins' !important;
  }

  .menu-drawer {
    background-color: ${props => props.theme.colors.green[500]} !important;
    padding: ${({ theme }) => theme.spacing.default};
    hr {
    margin: ${props => props.theme.spacing.sm} 0;
    color: white;
    border: unset;
    position: relative;
    height: 1px;
    :before {
      content: '';
      position: absolute;
      top: 0;
      left: 5%;
      right: 5%;
      width: 90%;
      height: 1px;
      background-image: linear-gradient(to right, transparent, white, transparent);
    }
  }
  }
`;

export default GlobalStyle;
