export const generateRandomArray = (length: number) => Array.from({ length }, () => Math.random().toString());

export const getFullUrl = (route: string) => {
  const companyId = window.location.pathname.match(/(\d+)/);
  if (companyId) {
    return `/${companyId[0]}/${route}`;
  }
  return window.location.pathname;
};

export const navigateToMagoPlan = () => {
  window.location.href = `${process.env.REACT_APP_MAGOPLAN_URL}`;
};

export const delay = async (timeout: number) => {
  return new Promise<void>(r => {
    setTimeout(() => {
      r();
    }, timeout);
  });
};
