import styled from 'styled-components';

export const Container = styled.div`
  b {
    color: ${({ theme }) => theme.colors.green[500]};
  }
  h3 {
    font-size: ${({ theme }) => theme.font.size.xl};
    text-align: center;
    font-weight: 600;
  }

  h4 {
    font-size: ${({ theme }) => theme.font.size.md};
    text-align: center;
    font-weight: 500;
    margin-top: ${({ theme }) => theme.spacing.default};
    b {
      font-size: ${({ theme }) => theme.font.size.default};
    }
  }

  h5 {
    text-align: center;
    font-size: ${({ theme }) => theme.font.size.md};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray[500]};
    margin-bottom: ${({ theme }) => theme.spacing.default};
  }
`;
