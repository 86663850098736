import styled from 'styled-components';

export const Container = styled.div`
  h1 {
    margin-bottom: ${({ theme }) => theme.spacing.default};
  }
`;

export const StepContent = styled.div`
  height: auto;
  padding: ${({ theme }) => theme.spacing.default};
`;

export const TrialInfoWrapper = styled.div``;
