import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  ScaleFade,
  Stack,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

// import { Container } from './styles';

type LoginFormData = { email: string; password: string };
export interface LoginModalProps {
  handleClose: () => void;
  handleSubmit: (data: LoginFormData) => Promise<void>;
  open: boolean;
}

export const ButtonActions = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${({ theme }) => theme.spacing.default};
`;

export const InputItem = styled.div``;

const LoginModal: React.FC<LoginModalProps> = ({ open, handleClose, handleSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [errors, setErrors] = useState<{ email?: string; password?: string }>({});
  const toast = useToast();

  const onSubmit = async () => {
    if (!email) {
      setErrors(prev => ({ ...prev, email: 'Campo obrigatório' }));
    }

    if (!password) {
      setErrors(prev => ({ ...prev, password: 'Campo obrigatório' }));
    }

    if (email && password) {
      setLoading(true);
      setErrors({});
      await handleSubmit({ email, password }).catch(() => {
        toast({
          status: 'error',
          isClosable: true,
          title: 'Credenciais inválidas',
        });
      });
      setLoading(false);
    }
  };

  return (
    <Modal onClose={() => {}} isOpen={open} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Email</ModalHeader>
        {/* <ModalCloseButton /> */}
        <ModalBody>
          <Stack spacing={4}>
            <InputItem>
              <label htmlFor='email'>Email</label>
              <FormControl isInvalid={!!errors?.email}>
                <InputGroup size='lg'>
                  <Input isInvalid={!!errors?.email} required errorBorderColor='red.400' id='email' variant='outline' placeholder='Seu email' onChange={e => setEmail(e.target.value)} value={email} />
                </InputGroup>
                <FormErrorMessage>{errors?.email}</FormErrorMessage>
              </FormControl>
            </InputItem>
            <InputItem>
              <label htmlFor='password'>Senha</label>
              <FormControl isInvalid={!!errors?.email}>
                <InputGroup size='lg'>
                  <Input
                    pr='4.5rem'
                    isInvalid={!!errors?.password}
                    type={!hiddenPassword ? 'text' : 'password'}
                    placeholder='Sua senha'
                    errorBorderColor='red.400'
                    isRequired
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                  />
                  <InputRightElement width='auto'>
                    <IconButton
                      m='0px 5px 0 0'
                      onClick={() => {
                        setHiddenPassword(!hiddenPassword);
                      }}
                      fontSize='22px'
                      size='md'
                      icon={hiddenPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                      aria-label='Mostrar / Esconder senha '
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{errors?.password}</FormErrorMessage>
              </FormControl>
            </InputItem>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ButtonActions>
            <Button isDisabled onClick={handleClose}>
              Fechar
            </Button>
            <Button
              // disabled={disableIf ? disableIf(localValue) || value === localValue : value === localValue}
              backgroundColor='green.500'
              color='white'
              _hover={{ backgroundColor: 'green.600' }}
              onClick={onSubmit}
              isDisabled={loading}
              isLoading={loading}
            >
              Entrar
            </Button>
          </ButtonActions>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LoginModal;
