import styled from 'styled-components';

export const Container = styled.div<{ clickable?: boolean; disable?: boolean }>`
  cursor: ${props => (props.disable ? 'not-allowed' : props.clickable ? 'pointer' : 'default')};
  filter: ${props => props.disable && 'grayscale(1)'};
  user-select: none;
  width: 100%;
  ${props => props.theme.medias.sm} {
    min-width: 290px;
  }
  max-width: 320px;
  min-width: 305px;
  height: 140px;
  border-radius: ${props => props.theme.style['border-radius'].default};
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing.sm};
  box-shadow: ${props => props.theme.style['box-shadow']};
  position: relative;
  > div {
    :first-child {
      width: 100%;
      flex: 1 1 auto;
    }
  }
  :hover {
    border: ${({ theme, clickable, disable }) => clickable && !disable && `2px solid ${theme.colors.green[500]}`};
    box-shadow: ${({ clickable, disable }) => clickable && !disable && `0 0 0 3px #aedbbf !important`};
  }
`;

export const ActionWrapper = styled.div`
  margin-top: ${props => props.theme.spacing.sm};
  position: absolute;
  right: ${({ theme }) => theme.spacing.sm};
  bottom: ${({ theme }) => theme.spacing.sm};
  flex: 0 1 auto;
  button {
    border-color: ${props => props.theme.colors.green[500]};
    font-size: ${props => props.theme.font.size.md};
    color: ${props => props.theme.colors.green[500]};
    transition: all 0.3s;
    :hover {
      background-color: ${props => props.theme.colors.green[500]};
      color: white;
    }
  }
`;
