import { Collapse, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import Stripe from 'stripe';
import styled from 'styled-components';
import InvoiceSuccessIcon from '../../../components/atoms/Icons/invoiceSuccess';
import { CardBrand, getCardBrandImage } from '../../../utils/card';
import { getDateByMs } from '../../../utils/date';
import { Column, ColumnCard, Container, InvoiceLine } from '../styles';
import { InvoiceResult } from '../types';

export const CardContentInvoicePaid = styled.div`
  padding: ${({ theme }) => theme.spacing.lg} 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  > a {
    cursor: pointer;
    display: flex;
    font-size: ${({ theme }) => theme.font.size.md};
    align-items: center;
    color: ${({ theme }) => theme.colors.gray[500]};
    :hover {
      color: ${({ theme }) => theme.colors.gray[700]};
    }
  }
  > span {
    font-size: ${({ theme }) => theme.font.size.md};
    margin-top: ${({ theme }) => theme.spacing.default};
    color: ${({ theme }) => theme.colors.gray[500]};
  }

  tfoot {
    tr.discount {
      th {
        /* :first-child {
            display: flex;
            align-items: center;
          } */
        span {
          margin-left: ${({ theme }) => theme.spacing.xs};
        }
        svg {
          display: unset;
        }
        font-size: ${({ theme }) => theme.font.size.md};
        color: ${({ theme }) => theme.colors.gray[500]};
        font-weight: 500;
      }
    }
    tr.total {
      th {
        font-size: ${({ theme }) => theme.font.size.lg};
        color: ${({ theme }) => theme.colors.green[500]};
      }
    }
  }
`;

export const InvoiceInfo = styled.div`
  margin-top: ${({ theme }) => theme.spacing.default};
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-column-gap: ${({ theme }) => theme.spacing.default}; */
`;

export const Fields = styled.div`
  color: ${({ theme }) => theme.colors.gray[500]};
  font-size: ${({ theme }) => theme.font.size.md};
  display: flex;
  flex-direction: column;
`;

export const Values = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.gray[700]};
  font-size: ${({ theme }) => theme.font.size.md};
  align-items: flex-end;
  span {
    display: flex;
    img {
      margin-right: ${({ theme }) => theme.spacing.xs};
      width: 23px;
    }
  }
`;

export interface InvoicePaidProps {
  data: InvoiceResult;
}

export const InvoicePaid: React.FC<InvoicePaidProps> = ({ data }) => {
  const [collapseItems, setCollapseItems] = useState(false);

  const payInformation = useMemo(() => {
    return {
      source: (data.invoice.charge as Stripe.Charge).source as Stripe.Card,
      date: getDateByMs(data.invoice.status_transitions.paid_at!),
    };
  }, [data.invoice]);

  return (
    <Container>
      <Column>
        <ColumnCard>
          <CardContentInvoicePaid>
            <InvoiceSuccessIcon />
            <span>Fatura paga</span>
            <h1>R${data.total.toFixed(2).replace('.', ',')}</h1>
            {collapseItems ? (
              <a onClick={() => setCollapseItems(false)}>
                Ocultar detalhes <MdOutlineKeyboardArrowUp />
              </a>
            ) : (
              <a onClick={() => setCollapseItems(true)}>
                Ver detalhes <MdOutlineKeyboardArrowDown />
              </a>
            )}
            <Collapse in={collapseItems}>
              <TableContainer>
                <Table variant='simple'>
                  <Tbody>
                    {data?.lines.map(line => (
                      <Tr key={line.id}>
                        <Td colSpan={2}>
                          <InvoiceLine>
                            <div>
                              <span>{line.displayName}</span>
                              <span>{line.description}</span>
                            </div>
                          </InvoiceLine>
                        </Td>
                        <Td isNumeric>R$ {line.value.toFixed(2).replace('.', ',')}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Collapse>
            <InvoiceInfo>
              <Fields>
                <span>Data de Pagamento</span>
                <span>Método de Pagamento</span>
              </Fields>
              <Values>
                <span>{payInformation.date}</span>
                <span>
                  <img src={getCardBrandImage(payInformation.source.brand as CardBrand)} alt='card' />
                  **** {payInformation.source.last4}
                </span>
              </Values>
            </InvoiceInfo>
          </CardContentInvoicePaid>
        </ColumnCard>
      </Column>
    </Container>
  );
};
