import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { StepsStyleConfig as StepsConfig } from 'chakra-ui-steps';
import App from './App';
import RootContext from './context';
import GlobalStyle from './globalStyle';
import theme from './theme';
import { ErrorBoundary } from './components/atoms/ErrorBoundary';

const ChakraTheme = extendTheme({
  components: {
    Steps: StepsConfig,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={ChakraTheme}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <BrowserRouter>
          <ErrorBoundary>
            <RootContext>
              <App />
            </RootContext>
          </ErrorBoundary>
        </BrowserRouter>
      </ThemeProvider>
    </ChakraProvider>
  </React.StrictMode>
);
