import { Button } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import check from '../../../assets/images/check.png';
import { getFullUrl } from '../../../utils/main';
import { Container, Content } from './styles';

export interface CheckoutSuccessProps {
  title: string;
  subtitle: string;
  onLeave: () => void;
}

const CheckoutSuccess: React.FC<CheckoutSuccessProps> = ({ title, subtitle, onLeave }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <img src={check} alt='success-icon' />
      <h2>{title}</h2>
      <h5>{subtitle}</h5>
      <Content>
        <Button
          backgroundColor='green.500'
          color='white'
          _hover={{ backgroundColor: 'green.600' }}
          onClick={() => {
            onLeave();
            navigate(getFullUrl('visao-geral'));
          }}
        >
          Voltar para página inicial
        </Button>
      </Content>
    </Container>
  );
};

export default CheckoutSuccess;
