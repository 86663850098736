import { theme as chakraUITheme } from '@chakra-ui/react';

export const getMedia = ([min, max]: [number | null, number | null]) => {
  if (!min) {
    return `@media only screen and (max-width: ${max}px)`;
  }
  if (!max) {
    return `@media only screen and (min-width: ${min}px)`;
  }
  return `@media only screen and (min-width: ${min}px) and (max-width: ${max}px)`;
};

type BreakPoints = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export const breakpoints = {
  small: 480,
  mobile: 576,
  tablet: 768,
  laptop: 992,
  desktop: 1280,
  large: 1440,
  wide: 1600,
};

const medias: Record<BreakPoints, string> = {
  /**
   * max-width: 480px
   */
  xxs: getMedia([null, breakpoints.small]),
  /**
   * max-width: 576px
   */
  xs: getMedia([null, breakpoints.mobile]),
  /**
   * max-width: 768px
   */
  sm: getMedia([null, breakpoints.tablet]),
  /**
   * max-width: 992px
   */
  md: getMedia([null, breakpoints.laptop]),
  /**
   * max-width: 1280px
   */
  lg: getMedia([null, breakpoints.desktop]),
  /**
   * max-width: 1440px
   */
  xl: getMedia([null, breakpoints.large]),
  /**
   * min-width: 1600px
   */
  xxl: getMedia([breakpoints.wide, null]),
};

export type SpacingList = {
  /**
   * 0px
   */
  none: string;
  /**
   * 2px
   */
  xxs: string;
  /**
   * 6px
   */
  xs: string;
  /**
   * 12px
   */
  sm: string;
  /**
   * 16px
   */
  default: string;
  /**
   * 24px
   */
  md: string;
  /**
   * 32px
   */
  lg: string;
  /**
   * 48px
   */
  xl: string;
  /**
   * 60px
   */
  xxl: string;
};

export const spacing: SpacingList = {
  none: '0px',
  xxs: '2px',
  xs: '6px',
  sm: '12px',
  default: '16px',
  md: '24px',
  lg: '32px',
  xl: '48px',
  xxl: '60px',
};

type FontType = {
  size: {
    /** 12px */
    sm: string;
    /** 14px */
    md: string;
    /** 16px */
    default: string;
    /** 18px */
    lg: string;
    /** 22px */
    xl: string;
    /** 30px */
    xxl: string;
    /** 45px */
    '2xl': string;
  };
};

const font: FontType = {
  size: {
    sm: '12px',
    md: '14px',
    default: '16px',
    lg: '18px',
    xl: '22px',
    xxl: '30px',
    '2xl': '45px',
  },
};

const theme = {
  colors: {
    ...chakraUITheme.colors,
    green: {
      50: '#EDF7F1',
      100: '#CEE9D8',
      200: '#AEDBBF',
      300: '#8ECDA5',
      400: '#6EBF8C',
      500: '#50B174',
      600: '#3F8D5C',
      700: '#2F6A45',
      800: '#1F472E',
      900: '#102317',
    },
  },
  style: {
    'box-shadow': 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
    'border-radius': {
      sm: '2px',
      default: '6px',
    },
  },
  medias,
  spacing,
  font,
};

type ThemeType = typeof theme;

export default theme;

export type { ThemeType };
