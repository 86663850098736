import styled from 'styled-components';

export const PriceName = styled.span`
  grid-area: name;
  font-size: ${props => props.theme.font.size.lg};
  strong {
    color: ${props => props.theme.colors.green[500]};
  }
`;

export const PriceMembers = styled.span`
  grid-area: members;
  font-size: ${props => props.theme.font.size.sm};
  color: ${props => props.theme.colors.gray[500]};
  font-weight: 300;
`;

export const PriceInstallmentPrice = styled.div`
  display: flex;
  align-items: center;
  grid-area: price;
  color: ${props => props.theme.colors.green[500]};
  div {
    display: flex;
    flex-direction: column;
    margin-right: ${props => props.theme.spacing.default};
  }
`;

export const PriceInstallments = styled.span`
  font-size: ${props => props.theme.font.size.sm};
`;

export const PriceCurrency = styled.span`
  font-size: ${props => props.theme.font.size.sm};
`;

export const PriceValue = styled.span`
  font-size: ${props => props.theme.font.size.xxl};
  font-weight: 700;
  color: ${props => props.theme.colors.green[500]};
  letter-spacing: 1px;
`;

export const PriceTotalPrice = styled.span`
  grid-area: total;
  font-size: ${props => props.theme.font.size.sm};
  color: ${props => props.theme.colors.gray[600]};
  font-weight: 300;
`;

export const UserMonthlyPrice = styled.span`
  grid-area: individual;
  font-size: ${props => props.theme.font.size.sm};
  color: ${props => props.theme.colors.gray[600]};
  font-weight: 300;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5) 10px;
  grid-template-areas:
    'name name name name .'
    'price price price . .'
    'price price price . .'
    'total total total . .'
    'individual individual individual . .';
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const CurrentPlanContent = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5) 10px;
  grid-template-areas:
    'name name name name .'
    'members members members . .'
    'price price price . .'
    'price price price . .'
    'total total total . .';
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;
