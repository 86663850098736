import { useState, useEffect } from 'react';
import { breakpoints } from '../theme';

export const useWindowDimensions = () => {
  const [isLaptop, setIsLaptop] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLaptop(window.innerWidth <= breakpoints.laptop);
      setIsTablet(window.innerWidth <= breakpoints.tablet);
      setIsMobile(window.innerWidth <= breakpoints.mobile);
    };
    window.addEventListener('resize', () => handleResize());
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { isLaptop, isMobile, isTablet };
};
