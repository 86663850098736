import { SimpleGrid } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Stripe from 'stripe';
import Card from '../../components/molecules/Card';
import PlanMembers from '../../components/molecules/Card/PlanMembers';
import CurrentPlan from '../../components/organisms/CurrentPlan';
import { useAppState } from '../../context/app';
import api from '../../services/api';
import theme from '../../theme';
import { checkIsTrial } from '../../utils/company';
import { getDateByMs } from '../../utils/date';
import { generateRandomArray, getFullUrl } from '../../utils/main';
import { Container, Section } from './styles';

const AvailablePlansPage: React.FC = () => {
  const [state] = useAppState();
  const [plans, setPlans] = useState<Stripe.Product[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const cancelDate = state.customer?.subscription && state.customer?.subscription?.cancel_at_period_end ? getDateByMs(state.customer.subscription.current_period_end) : undefined;
  const willUpdate = !!state.customer?.subscriptionSchedule;
  const willStart = !!state.customer?.subscriptionSchedule && state.customer?.subscriptionSchedule.status === 'not_started';
  const isDisabled = !!cancelDate || willUpdate || willStart;

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await api.get<Stripe.Product[]>('/plans');
      setPlans(res.data);
      setLoading(false);
    })();
  }, []);

  return (
    <Container>
      <Section>
        <h2>Plano atual</h2>
        <CurrentPlan readOnly subscription={state.customer?.subscription} />
      </Section>

      <Section>
        <h2>Planos disponíveis</h2>
        <SimpleGrid justifyItems='center' minChildWidth='305px' spacing={theme.spacing.default}>
          {loading
            ? generateRandomArray(6).map(item => <Card key={item} loading component={<PlanMembers.Skeleton />} />)
            : plans.map(plan => (
                <Card
                  clickable
                  disable={isDisabled}
                  onClick={() => !isDisabled && navigate(getFullUrl(`trocar-plano?product=${plan.id}`))}
                  key={plan.id}
                  component={
                    <PlanMembers
                      members={Number(plan.metadata.members)}
                      maxDiscount={Number(plan.metadata.max_discount)}
                      basePrice={Number(plan.metadata.base_price)}
                      gBPerMember={Number(plan.metadata.gb_per_member)}
                      name={plan.metadata.name}
                    />
                  }
                />
              ))}
        </SimpleGrid>
      </Section>
    </Container>
  );
};

export default AvailablePlansPage;
