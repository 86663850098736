import { Button } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ApiError } from '../../../utils/axios';
import { navigateToMagoPlan } from '../../../utils/main';

import { Container, Content } from './styles';

const AppError: React.FC<{ error?: ApiError }> = ({ error }) => {
  const { state } = useLocation();
  const message = error?.message || (state as any).message || 'Ocorreu um erro inesperado';
  return (
    <Container>
      <Content>
        <span>{message}</span>
        <Button onClick={() => navigateToMagoPlan()}>Voltar para Mago Plan</Button>
      </Content>
    </Container>
  );
};

export default AppError;
