import styled from 'styled-components';

export const Container = styled.div`
  section {
    :not(:first-child) {
      margin-top: ${({ theme }) => theme.spacing.default};
    }
  }
`;

export const Section = styled.section`
  h2 {
    margin-bottom: ${({ theme }) => theme.spacing.default};
  }
`;
