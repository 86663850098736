import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.default};
  img {
    margin-bottom: ${({ theme }) => theme.spacing.lg};
    width: 70px;
  }

  h2 {
    text-align: center;
  }
  h5 {
    text-align: center;
    color: ${({ theme }) => theme.colors.gray[500]};
  }
`;

export const Content = styled.div`
  margin-top: ${({ theme }) => theme.spacing.default};
`;
