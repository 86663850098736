import { Badge, Box, CircularProgress, HStack, IconButton, Input, Table, TableCaption, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr, useNumberInput } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import Stripe from 'stripe';
import Button from '../../components/atoms/Button';
import { useAppState } from '../../context/app';
import api from '../../services/api';
import { getDateByMs } from '../../utils/date';
import { parseStripeValueToPrice } from '../../utils/stripe';
import { LoadingWrapper, PaginationButtons } from './styles';

const InvoiceBadge = ({ status }: { status: Stripe.Invoice['status'] }) => {
  if (status === 'open') {
    return <Badge colorScheme='blue'>Em Aberto</Badge>;
  }
  if (status === 'paid') {
    return <Badge colorScheme='green'>Paga</Badge>;
  }

  if (status === 'draft') {
    return <Badge colorScheme='grey'>Rascunho</Badge>;
  }
  return <></>;
};

const PaymentsPage: React.FC = () => {
  const [{ customer }] = useAppState();
  const [loading, setLoading] = useState(true);
  const [startingAfter, setStartingAfter] = useState('');
  const [endingBefore, setEndingBefore] = useState('');
  const [invoices, setInvoices] = useState<Stripe.ApiList<Stripe.Invoice>>();
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps, valueAsNumber } = useNumberInput({
    step: 1,
    defaultValue: 1,
    min: 1,
  });
  const pageNumber = useRef(valueAsNumber);

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();

  useEffect(() => {
    if (pageNumber.current < valueAsNumber) {
      const lastInvoice = invoices!.data.at(-1);
      if (lastInvoice) {
        setStartingAfter(lastInvoice.id);
        setEndingBefore('');
      }
    } else if (pageNumber.current > valueAsNumber) {
      setEndingBefore(invoices!.data[0].id);
      setStartingAfter('');
    }
    pageNumber.current = valueAsNumber;
  }, [valueAsNumber, invoices]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await api.get<Stripe.ApiList<Stripe.Invoice>>(`/customers/${customer.data!.id!}/invoices`, {
        params: { limit: 20, starting_after: startingAfter || undefined, ending_before: endingBefore || undefined },
      });
      setInvoices(res.data);
      setLoading(false);
    })();
  }, [customer.data, endingBefore, startingAfter]);

  return (
    <TableContainer>
      <div>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>Data</Th>
              <Th textAlign='center'>Status</Th>
              <Th isNumeric>Número do pedido</Th>
              <Th isNumeric>Valor</Th>
              {/* <Th isNumeric>Arquivo</Th> */}
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <Tr>
                <Td colSpan={5}>
                  <LoadingWrapper>
                    <CircularProgress isIndeterminate color='green.500' />
                  </LoadingWrapper>
                </Td>
              </Tr>
            ) : (
              invoices?.data.map(invoice => (
                <Tr key={invoice.id}>
                  <Td>{getDateByMs(invoice.created)}</Td>
                  <Td textAlign='center'>
                    <InvoiceBadge status={invoice.status} />
                  </Td>
                  <Td isNumeric>{invoice.number}</Td>
                  <Td isNumeric>R${parseStripeValueToPrice(invoice.total)}</Td>
                  {/* <Td isNumeric>
                    <IconButton aria-label='download-button'>
                      <AiOutlineDownload />
                    </IconButton>
                  </Td> */}
                </Tr>
              ))
            )}
          </Tbody>
          <Tfoot>
            <Tr>
              <Td colSpan={5} border='unset'>
                <Box width='100%' display='flex' justifyContent='flex-end'>
                  <HStack maxW='320px'>
                    <Button {...dec} disabled={valueAsNumber === 1}>
                      Anterior
                    </Button>
                    {/* <Input {...input} disabled /> */}
                    <Button {...inc} disabled={!invoices?.has_more}>
                      Próximo
                    </Button>
                  </HStack>
                </Box>
              </Td>
            </Tr>
          </Tfoot>
        </Table>
      </div>
    </TableContainer>
  );
};

export default PaymentsPage;
