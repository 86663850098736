import { Skeleton } from '@chakra-ui/react';
import React from 'react';
import Stripe from 'stripe';
import { CardBrand as CardBrandType, getCardBrandImage, getCardBrandReadableName } from '../../../utils/card';

import { Container, CardBrand, CardNumber, CardValidity, CardBrandImage } from './styles';

export interface PaymentCardProps {
  data?: Stripe.Card;
  selected?: boolean;
  error?: boolean;
  loading?: boolean;
  clickable?: boolean;
  onClick?: (data: Stripe.Card) => void;
  rightIcon?: React.ReactNode;
}

export const PaymentCardSkeleton = () => (
  <>
    <Skeleton height='50px' width='50px' />
    <div>
      <CardBrand>
        <Skeleton height='20px' width='40px' />
      </CardBrand>
      <div>
        <Skeleton height='20px' width='60px' mr={4} />
        <Skeleton height='20px' width='60px' />
      </div>
    </div>
  </>
);

const PaymentCard: React.FC<PaymentCardProps> = ({ data, selected, clickable, error, loading, onClick, rightIcon }) => {
  if (loading || !data) {
    return (
      <Container error={error} selected={selected} clickable={clickable}>
        <PaymentCardSkeleton />
      </Container>
    );
  }

  return (
    <Container error={error} selected={selected} clickable={clickable} onClick={onClick ? () => onClick(data) : undefined}>
      <div>
        <CardBrandImage src={getCardBrandImage(data.brand as CardBrandType)} />
        <div>
          <CardBrand>{getCardBrandReadableName(data.brand as CardBrandType)}</CardBrand>
          <div>
            <CardNumber>**** {data?.last4}</CardNumber>
            <CardValidity>
              {data?.exp_month}/{data?.exp_year}
            </CardValidity>
          </div>
        </div>
      </div>
      {rightIcon}
    </Container>
  );
};

export default PaymentCard;
