import { SimpleGrid, Skeleton } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import Stripe from 'stripe';
import { useCheckoutData } from '..';
import Card from '../../../components/molecules/Card';
import Price from '../../../components/molecules/Card/Price';
import { useAppState } from '../../../context/app';
import theme from '../../../theme';
import { PriceMetadataType } from '../../../types/price';
import { generateRandomArray } from '../../../utils/main';
import { Container } from './styles';

export interface Step1Props {
  prices: Stripe.Price[];
  loading?: boolean;
}

const Step1: React.FC<Step1Props> = ({ prices, loading }) => {
  const [state] = useAppState();
  const [checkoutData, setCheckoutData] = useCheckoutData();

  const currentPlan = useMemo(() => {
    if (state.customer?.subscription) {
      return state.customer?.subscription?.items.data.find(item => item.price.metadata.type === PriceMetadataType.PLAN)?.price;
    }
    return undefined;
  }, [state.customer?.subscription]);

  if (prices.length === 0 && !loading) {
    return <h3>Não encontramos nenhum plano para essa quantidade de membros</h3>;
  }

  const membersNumber = prices.length === 0 ? 0 : Number((prices[0].product as Stripe.Product).metadata.members);
  const gBPerUser = prices.length === 0 ? 0 : Number((prices[0].product as Stripe.Product).metadata.gb_per_member);
  const economyPerUser = prices.length === 0 ? 0 : (65 - Number((prices[0].product as Stripe.Product).metadata.base_price)).toPrecision(4).replace('.', ',');

  const onSelectPrice = (selectedPrice: Stripe.Price) => {
    setCheckoutData(prev => ({ ...prev, selectedPrice }));
  };

  return (
    <Container>
      <h3>
        {loading ? (
          <Skeleton width='100%' height='20px' />
        ) : (
          <>
            Parabéns, você escolheu o <b>Plano {membersNumber}</b>
          </>
        )}
      </h3>
      <h5>
        {loading ? (
          <Skeleton width='40%' />
        ) : (
          `Seu plano inclui ${membersNumber} membros e ${membersNumber * gBPerUser} GB de armazenamento. Nesse plano você economiza até R$ ${economyPerUser} por usuário.`
        )}
      </h5>
      <SimpleGrid justifyItems='center' minChildWidth='305px' spacing={theme.spacing.default}>
        {loading
          ? generateRandomArray(4).map(item => <Card key={item} loading component={<Price.Skeleton />} />)
          : prices.map(price => (
              <Card
                key={price.id}
                buttonDisable={price.id === currentPlan?.id}
                buttonText={price.id === currentPlan?.id ? 'Plano Atual' : 'Selecionar'}
                selected={checkoutData.selectedPrice?.id === price.id}
                component={<Price data={price} />}
                onClick={() => onSelectPrice(price)}
                clickable
              />
            ))}
      </SimpleGrid>

      {loading ? (
        <Skeleton margin='32px' width='100%' height='10px' />
      ) : (
        <h4>
          <b>Gostaria de mais usuários?</b> Na próxima página você poderá simular os valores com usuários extras para este plano
        </h4>
      )}
    </Container>
  );
};

export default Step1;
