import styled from 'styled-components';

export const Quantity = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray[500]};
  font-size: ${({ theme }) => theme.font.size.md};

  span {
    margin-right: ${props => props.theme.spacing.xs};
  }
  div {
    display: flex;
    align-items: center;
  }
`;

export const SubscriptionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    :first-child {
      display: flex;
      flex-direction: column;

      span {
        :last-child {
          font-size: ${({ theme }) => theme.font.size.md};
          color: ${({ theme }) => theme.colors.gray[500]};
        }
      }
    }
  }
`;
