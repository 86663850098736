import { Button, Collapse, IconButton, IconButtonProps, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import Stripe from 'stripe';
import { useAppState } from '../../../context/app';
import { removeCardAction } from '../../../context/app/actions';
import theme from '../../../theme';
import { Card } from '../../../types/card';
import { ApiError, isAxiosError } from '../../../utils/axios';
import { validateCardData } from '../../../utils/card';
import ConfirmationModal, { ConfirmationModalProps } from '../../molecules/ConfirmationModal';
import PaymentCard from '../../molecules/PaymentCard';
import CreateCardForm, { CreateCardFormProps } from '../Forms/CreateCard';
import { CreateCardActionWrapper, CreateCardFormWrapper, CreateNew } from './styles';

export interface CardsDialogProps {
  open: boolean;
  onClose: () => void;
  cards: Stripe.Card[];
  onAddNewCard: (data: Card) => Promise<void | Stripe.Card>;
  selectedCard?: Stripe.Card;
  disableIf?: (selectedCard: Stripe.Card) => boolean;
  onSubmitSelectedCard: (card: Stripe.Card) => void | Promise<void>;
}

const newCardInitial = {
  cardHolder: '',
  cardNumber: '',
  cvv: '',
  validity: '',
};

const defaultConfirmDialogState = {
  open: false,
  title: '',
  text: '',
  onClose: () => {},
  onOk: () => {},
};
const DeleteIcon = ({ onClick }: { onClick: (event: React.MouseEvent) => void }) => {
  return <IconButton colorScheme='red' aria-label='Call Delete' size='md' icon={<BsTrash />} onClick={onClick} />;
};

const CardsDialog: React.FC<CardsDialogProps> = ({ open, onClose, cards, selectedCard, onAddNewCard, onSubmitSelectedCard, disableIf }) => {
  const [, dispatch] = useAppState();
  const [localSelectedCard, setLocalSelectedCard] = useState(selectedCard);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [confirmModalState, setConfirmModalState] = useState<ConfirmationModalProps>(defaultConfirmDialogState);
  const [newCard, setNewCard] = useState<Card>(newCardInitial);
  const [newCardLoading, setNewCardLoading] = useState<boolean>(false);
  const [newCardFormErrors, setNewCardFormErrors] = useState<CreateCardFormProps['errors']>();
  const [triggerErrorsWatch, setTriggerErrorsWatch] = useState(false);
  const toast = useToast();
  const handleCardChange: CreateCardFormProps['onChange'] = (field, value) => {
    setNewCard(prev => ({ ...prev, [field]: value }));
  };

  const handleClose = () => {
    if (loadingSubmit || newCardLoading) return;
    setAddNew(false);
    setNewCardFormErrors(undefined);
    setLocalSelectedCard(selectedCard);
    onClose();
  };

  const handleCancelAddNew = () => {
    setAddNew(false);
    setNewCardFormErrors(undefined);
    setTriggerErrorsWatch(false);

    setNewCard(newCardInitial);
  };

  const handleAddNew = async () => {
    setNewCardLoading(true);
    const errors = validateCardData(newCard);
    if (Object.keys(errors).length > 0) {
      setNewCardLoading(false);
      setNewCardFormErrors(errors);
      setTriggerErrorsWatch(true);
      return;
    }
    try {
      await onAddNewCard(newCard);
      setNewCard(newCardInitial);
      setAddNew(false);
      toast({
        status: 'success',
        isClosable: true,
        title: 'Cartão adicionado com sucesso',
      });
    } catch (error) {
      if (isAxiosError<ApiError>(error) && error?.response?.data.message) {
        toast({
          status: 'error',
          isClosable: true,
          title: error.response.data.message,
        });
      }
    } finally {
      setNewCardLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoadingSubmit(true);
      await onSubmitSelectedCard(localSelectedCard!);
      setLoadingSubmit(false);
      handleClose();
    } catch (error) {
      toast({
        status: 'error',
        isClosable: true,
        title: (error as any).response.data.message,
      });
    }
  };

  const handleDeleteCard = (id: string) => (event: React.MouseEvent) => {
    event.stopPropagation();
    setConfirmModalState({
      open: true,
      title: 'Aviso',
      text: 'Essa ação irá remover este cartão permanentemente, tem certeza que deseja continuar?',
      onClose: () => setConfirmModalState(defaultConfirmDialogState),
      onOk: async () => {
        await removeCardAction(dispatch, id);
        toast({
          status: 'success',
          isClosable: true,
          title: 'Cartão deletado com sucesso',
        });
      },
    });
  };

  useEffect(() => {
    if (triggerErrorsWatch) {
      const errors = validateCardData(newCard);
      setNewCardFormErrors(errors);
    }
  }, [newCard, triggerErrorsWatch]);

  return (
    <Modal isOpen={open} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Escolha a forma de pagamento</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing='16px'>
            {cards.map(card => (
              <PaymentCard
                onClick={() => setLocalSelectedCard(card)}
                selected={card.id === localSelectedCard?.id}
                clickable
                key={card.id}
                data={card}
                rightIcon={selectedCard?.id !== card?.id && card.id !== localSelectedCard?.id && <DeleteIcon onClick={handleDeleteCard(card.id)} />}
              />
            ))}
            {!addNew && <CreateNew onClick={() => setAddNew(true)}>Adicionar novo</CreateNew>}
            <Collapse in={addNew} animateOpacity>
              <CreateCardFormWrapper>
                <CreateCardForm errors={newCardFormErrors} onChange={handleCardChange} data={newCard} />
                <CreateCardActionWrapper>
                  <Button variant='ghost' disabled={newCardLoading} color='red.500' _hover={{ backgroundColor: 'red.500', color: 'white' }} mr={theme.spacing.default} onClick={handleCancelAddNew}>
                    Cancelar
                  </Button>
                  <Button variant='ghost' isLoading={newCardLoading} color='green.400' _hover={{ backgroundColor: 'green.500', color: 'white' }} onClick={handleAddNew}>
                    Adicionar
                  </Button>
                </CreateCardActionWrapper>
              </CreateCardFormWrapper>
            </Collapse>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button disabled={loadingSubmit || newCardLoading} variant='ghost' _hover={{ backgroundColor: 'gray.300' }} mr={theme.spacing.sm} onClick={handleClose}>
            Fechar
          </Button>
          <Button
            disabled={loadingSubmit || !localSelectedCard || (disableIf && disableIf(localSelectedCard))}
            isLoading={loadingSubmit}
            bgColor='green.500'
            color='white'
            _hover={{ backgroundColor: 'green.600' }}
            onClick={handleSubmit}
          >
            Selecionar
          </Button>
        </ModalFooter>
      </ModalContent>
      <ConfirmationModal {...confirmModalState} />
    </Modal>
  );
};

export default CardsDialog;
