import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    display: flex;
    align-items: baseline;
    div.chakra-skeleton {
      :first-child {
        margin-right: ${({ theme }) => theme.spacing.xs};
      }
    }
  }

  > span {
    font-size: ${props => props.theme.font.size.xl};
  }

  > div.members > span {
    :first-child {
      margin-right: ${({ theme }) => theme.spacing.xs};
      margin-top: ${({ theme }) => theme.spacing.xs};
      margin-bottom: ${({ theme }) => theme.spacing.sm};

      font-weight: 700;
      color: ${props => props.theme.colors.green[500]};
      font-size: ${props => props.theme.font.size['2xl']};
      line-height: 80%;
    }
  }

  > div.storage > span {
    :first-child {
      // font-weight: 700;
      margin-right: 2px;
    }
    font-size: ${props => props.theme.font.size.sm};
  }
  > div.price {
    font-size: ${props => props.theme.font.size.sm};
  }
`;
