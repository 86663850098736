import styled from 'styled-components';

export const Container = styled.div``;

export const Section = styled.section`
  margin-top: ${props => props.theme.spacing.default};

  > button {
    margin-top: ${props => props.theme.spacing.default};
  }
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  h2 {
    color: ${({ theme }) => theme.colors.gray[600]};
    margin-right: ${props => props.theme.spacing.default};
  }
  margin-bottom: ${props => props.theme.spacing.default};
  h5 {
    color: ${({ theme }) => theme.colors.gray[600]};
    font-size: ${({ theme }) => theme.font.size.md};
  }
`;

export const PaymentSectionContentWrapper = styled.div`
  ${props => props.theme.medias.sm} {
    max-width: 60%;
  }
  ${props => props.theme.medias.xs} {
    max-width: 80%;
  }
  max-width: 40%;
  display: flex;
  flex-direction: column;
  b {
    color: ${({ theme }) => theme.colors.green[500]};
  }
  > span {
    margin-bottom: ${({ theme }) => theme.spacing.default};
  }

  button {
    width: 40%;
  }
`;
