import styled from 'styled-components';

export const MemberCard = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.style['border-radius'].default};
  padding: ${({ theme }) => theme.spacing.default};
  ${props =>
    props.selected
      ? `
  border: 2px solid ${props.theme.colors.green[500]};
  box-shadow: 0 0 0 3px #aedbbf !important;
  `
      : `
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  `}
`;

export const MemberName = styled.span`
  font-size: ${({ theme }) => theme.font.size.md};
  margin-top: ${({ theme }) => theme.spacing.default};
`;

export const ModalHeaderWrapper = styled.div`
  p {
    font-size: ${({ theme }) => theme.font.size.md};
    font-weight: 500;
    color: ${({ theme }) => theme.colors.gray[500]};
    line-height: 18px;
  }
`;
