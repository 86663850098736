import { Alert, AlertIcon, Avatar, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import theme from '../../../theme';
import { Member } from '../../../types/company';
import { MemberCard, MemberName, ModalHeaderWrapper } from './styles';

type MemberType = Member & { selected?: boolean };

export interface MembersDialogProps {
  members: MemberType[];
  overflowNumber: number;
  open: boolean;
  onClose: () => void;
  onSubmit: (members: Member[]) => void | Promise<void>;
}

const MembersDialog: React.FC<MembersDialogProps> = ({ members, open, onClose, onSubmit, overflowNumber }) => {
  const [localMembers, setLocalMembers] = useState(members);
  const [loading, setLoading] = useState(false);

  const selectedMembersCount = useMemo(
    () =>
      localMembers.reduce((acc, member) => {
        if (member.selected) {
          return acc + 1;
        }
        return acc;
      }, 0),
    [localMembers]
  );

  useEffect(() => {
    setLocalMembers(members);
  }, [members]);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    // Spread operator does not work with shallow copies (maybe a Ecmas change)
    const copyLocalMembers: MemberType[] = JSON.parse(JSON.stringify(localMembers));
    const selectedMembers: Member[] = copyLocalMembers
      .filter(member => member.selected)
      .map(member => {
        delete member.selected;
        return member;
      });
    await onSubmit(selectedMembers);
    setLoading(false);
  }, [localMembers, onSubmit]);

  const handleSelect = (member: MemberType) => {
    setLocalMembers(prev => {
      return prev.map(prevMember => {
        if (prevMember.id === member.id) {
          if (prevMember.selected === undefined) {
            return { ...prevMember, selected: true };
          }
          return { ...prevMember, selected: !prevMember.selected };
        }
        return prevMember;
      });
    });
  };

  return (
    <Modal size='lg' isOpen={open} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeaderWrapper>
          <ModalHeader>
            <h3>Remova membros da sua empresa</h3>
            <p>
              Selecione os membros que deseja <b>remover</b> da empresa para prosseguir com a assinatura
            </p>
          </ModalHeader>
        </ModalHeaderWrapper>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid columns={2} spacing={5} marginBottom={theme.spacing.default}>
            {localMembers.map(member => (
              <MemberCard onClick={() => handleSelect(member)} selected={!!member.selected} key={member.id}>
                <Avatar src={member.avatar} name={member.name} />
                <MemberName>{member.name}</MemberName>
              </MemberCard>
            ))}
          </SimpleGrid>
          {selectedMembersCount < overflowNumber ? (
            <Alert status='warning'>
              <AlertIcon />
              Selecione mais&nbsp;<b>{overflowNumber - selectedMembersCount}</b>&nbsp;membro(s)
            </Alert>
          ) : (
            <Alert status='success'>
              <AlertIcon />
              Está tudo certo para continuar
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button disabled={loading} variant='ghost' _hover={{ backgroundColor: 'gray.300' }} mr={theme.spacing.sm} onClick={handleClose}>
            Fechar
          </Button>
          <Button disabled={loading || selectedMembersCount < overflowNumber} isLoading={loading} bgColor='green.500' color='white' _hover={{ backgroundColor: 'green.600' }} onClick={handleSubmit}>
            Continuar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MembersDialog;
