import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.gray[400]};
  height: 100%;
  width: 100%;
  background-color: white;
  > div {
    height: 110px !important;
  }
  span {
    color: ${props => props.theme.colors.gray[400]};
  }
`;
