export enum SubscriptionStatus {
  ACTIVE = 'active',
  TRIALING = 'trialing',
  CANCELED = 'canceled',
  PAST_DUE = 'past_due',
}

export type CreateSubscriptionDTO = {
  default_source: string;
  prorationDate?: number;
  items: {
    price: string;
    quantity: number;
  }[];
  members_to_remove: number[];
};
