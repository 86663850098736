import styled from 'styled-components';

export const CreateNew = styled.div`
  cursor: pointer;
  height: 70px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray[200]};
  border-radius: ${({ theme }) => theme.style['border-radius'].default};
  border: 2px dashed ${({ theme }) => theme.colors.gray[500]};
  color: ${({ theme }) => theme.colors.gray[700]};
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CreateCardFormWrapper = styled.div`
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  padding: ${({ theme }) => theme.spacing.default};
  margin: ${({ theme }) => theme.spacing.xs};
  border-radius: ${({ theme }) => theme.style['border-radius'].default};
`;

export const CreateCardActionWrapper = styled.div`
  display: grid;
  margin-top: ${({ theme }) => theme.spacing.default};
  grid-template-columns: 1fr 1fr;
  column-gap: ${({ theme }) => theme.spacing.default};
`;
