import React from 'react';
import { Content, PriceInstallmentPrice, PriceName, PriceTotalPrice, PriceValue } from '../styles';

const FreePlan: React.FC = () => {
  return (
    <Content>
      <PriceName>Plano Grátis</PriceName>
      {/* <PriceMembers>1 membro incluso</PriceMembers> */}
      <PriceInstallmentPrice>
        <PriceValue>Grátis</PriceValue>
      </PriceInstallmentPrice>
      <PriceTotalPrice>(R$0,00 por Mês)</PriceTotalPrice>
    </Content>
  );
};

export default FreePlan;
