import React from 'react';
import { Skeleton } from '@chakra-ui/react';
import Stripe from 'stripe';
import { CurrentPlanContent, PriceName, PriceMembers, PriceCurrency, PriceInstallments, PriceValue, PriceInstallmentPrice, PriceTotalPrice } from '../styles';
import { mapPeriodicityToText } from '../../../../../utils/plan';

export interface PriceProps {
  data: Stripe.Price;
  product?: Stripe.Product;
}

const PriceSkeleton = () => {
  return (
    <CurrentPlanContent>
      <PriceName>
        <Skeleton width='200px' height='22px' />
      </PriceName>
      <PriceMembers>
        <Skeleton marginTop='5px' marginBottom='5px' width='100px' height='18px' />
      </PriceMembers>
      <PriceInstallmentPrice>
        <div>
          <PriceInstallments>
            <Skeleton marginBottom='2px' width='15px' height='15px' />
          </PriceInstallments>
          <PriceCurrency>
            <Skeleton width='15px' height='15px' />
          </PriceCurrency>
        </div>
        <PriceValue>
          <Skeleton width='100px' height='30px' />
        </PriceValue>
      </PriceInstallmentPrice>
      <PriceTotalPrice>
        <Skeleton marginTop='5px' width='150px' height='18px' />
      </PriceTotalPrice>
    </CurrentPlanContent>
  );
};

const CurrentPlan: React.FC<PriceProps> & { Skeleton: React.FC } = ({ data, product }) => {
  const members = Number(data.metadata.members);
  const planValue = (data.unit_amount! / 100).toFixed(2).replace('.', ',');
  return (
    <CurrentPlanContent>
      <PriceName>{product?.metadata?.name || '...'}</PriceName>
      <PriceMembers>{members === 1 ? '1 membro incluso' : `${members} membros inclusos`}</PriceMembers>
      <PriceInstallmentPrice>
        <div>
          <PriceInstallments>1x</PriceInstallments>
          <PriceCurrency>R$</PriceCurrency>
        </div>
        <PriceValue>{planValue}</PriceValue>
      </PriceInstallmentPrice>
      <PriceTotalPrice>
        (R${planValue} por {mapPeriodicityToText(data.recurring)})
      </PriceTotalPrice>
    </CurrentPlanContent>
  );
};

CurrentPlan.Skeleton = PriceSkeleton;

export default CurrentPlan;
