import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import { Button, Flex, useToast } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Stripe from 'stripe';
import { Container, StepContent } from './styles';
import api from '../../services/api';
import Step1 from './Step1';
import Step2 from './Step2';
import { CreateCardFormProps } from '../../components/organisms/Forms/CreateCard';
import { mapPeriodicityToText } from '../../utils/plan';
import { validateCardData } from '../../utils/card';
import { addNewCard, setCustomerAdditionalInfoAction } from '../../context/app/actions';
import { useAppState } from '../../context/app';
import { isAxiosError } from '../../utils/axios';
import CheckoutSuccess from '../../components/organisms/CheckoutSuccess';
import { delay, getFullUrl } from '../../utils/main';
import { getCustomerCards, getCustomerSubscriptionSchedule, getCustomerUpcomingInvoice } from '../../utils/customer';
import { PriceMetadataType } from '../../types/price';
import MembersDialog, { MembersDialogProps } from '../../components/organisms/MembersDialog';
import { CreateSubscriptionDTO, SubscriptionStatus } from '../../types/subscription';
import { getDateByMs } from '../../utils/date';
import { checkIsTrial } from '../../utils/company';
import ConfirmationModal, { ConfirmationModalProps } from '../../components/molecules/ConfirmationModal';

const steps = [{ label: 'Planos' }, { label: 'Pagamento' }];

type PreviewData = { total: number; discount: number; prorationDate: number; subscription_current_period_end: number };

const membersDialogDefaultData: MembersDialogProps = {
  open: false,
  overflowNumber: 0,
  members: [],
  onClose: () => {},
  onSubmit: () => {},
};

export type SubscriptionItemType = {
  id: string;
  name: string;
  detail?: string;
  readonly?: boolean;
  hide?: boolean;
  metadata: {
    members: string;
    type: string;
    base_price: string;
    max_discount: string;
  };
  unitPrice: number;
  quantity: number;
  selectQuantity?: boolean;
};

type CheckoutData = {
  flow: 'plan' | 'additionalMember';
  preview?: PreviewData;
  loading?: boolean;
  loadingItems?: boolean;
  selectedPrice?: Stripe.Price;
  selectedCard?: Stripe.Card;
  subscriptionItems: SubscriptionItemType[];
  cart: SubscriptionItemType[];
  cardReadOnly: boolean;
  hideBack: boolean;
  isDowngrade?: boolean;
  discount?: number;
  discountMessage?: string;
  infos?: string[];
  nextSteps?: string[];
  overrideOnChangeQuantity?: (subscriptionItems: SubscriptionItemType[], item: SubscriptionItemType | undefined, value: number) => void;
  overrideConclude?: (nextStepFn: () => void, items: SubscriptionItemType[]) => () => Promise<void>;
  firstCard: {
    data: CreateCardFormProps['data'];
    errors?: CreateCardFormProps['errors'];
    watchErrors?: boolean;
  };
};
const checkoutInitialData: CheckoutData = {
  flow: 'plan',
  loading: false,
  loadingItems: false,
  subscriptionItems: [],
  cart: [],
  cardReadOnly: false,
  isDowngrade: false,
  hideBack: false,
  firstCard: {
    data: {
      cardHolder: '',
      cardNumber: '',
      cvv: '',
      validity: '',
    },
  },
};
const CheckoutDataContext = React.createContext<[CheckoutData, React.Dispatch<React.SetStateAction<CheckoutData>>]>([checkoutInitialData, () => ({})]);
export const useCheckoutData = () => React.useContext(CheckoutDataContext);
const defaultWarnDialogState = {
  open: false,
  title: '',
  text: '',
  onClose: () => {},
  onOk: () => {},
};
const successPageDefaultData = {
  title: '',
  subtitle: '',
};

const ChangePlanPage: React.FC = () => {
  const [params] = useSearchParams();
  const toast = useToast();
  const navigate = useNavigate();
  const [{ customer, company }, dispatch] = useAppState();
  const [prices, setPrices] = useState<{ loading: boolean; data: Stripe.Price[] }>({ loading: false, data: [] });
  const [warnDialogState, setWarnDialogState] = useState<ConfirmationModalProps>(defaultWarnDialogState);
  const [successPageData, setSuccessPageData] = useState(successPageDefaultData);
  const [checkoutData, setCheckoutData] = useState<CheckoutData>(checkoutInitialData);
  const [membersDialogData, setMembersDialogData] = useState<MembersDialogProps>(membersDialogDefaultData);
  const { nextStep, prevStep, activeStep, setStep } = useSteps({
    initialStep: 0,
  });

  const isLastStep = activeStep === steps.length - 1;
  const currentPlanMembers = useMemo(() => {
    if (!customer?.subscription) return 1;
    const currentPlan = customer.subscription.items.data.find(item => item.price.metadata.type === PriceMetadataType.PLAN);
    return Number(currentPlan?.price.metadata.members);
  }, [customer?.subscription]);
  const isTrial = checkIsTrial(company);
  const cancelDate = customer?.subscription && customer?.subscription?.cancel_at_period_end ? getDateByMs(customer.subscription.current_period_end) : undefined;
  const willUpdate = !!customer?.subscriptionSchedule;
  const willStart = !!customer?.subscriptionSchedule && customer?.subscriptionSchedule.status === 'not_started';

  useEffect(() => {
    return () => {
      setSuccessPageData(successPageDefaultData);
    };
  }, []);

  useEffect(() => {
    if ((cancelDate || willStart) && activeStep !== steps.length) {
      navigate(getFullUrl('visao-geral'));
    }
  }, [activeStep, cancelDate, navigate, willStart, willUpdate]);

  useEffect(() => {
    const step = params.get('step');
    if (step) {
      setStep(Number(step) - 1);
    }
  }, [params, setStep]);

  // TODO - REFACTOR CODE
  useEffect(() => {
    (async () => {
      const product = params.get('product');
      const additional = params.get('additional');
      const membersQuantity = params.get('quantity');

      //* Update additional members flow starts here
      if (additional) {
        let preview: PreviewData | undefined;
        let isDowngrade = false;
        let discount: number;
        let discountMessage: string;
        let overrideTotal: number | undefined;
        const cart: SubscriptionItemType[] = [];

        const getPreview = async (subItems: SubscriptionItemType[]) => {
          setCheckoutData(prev => (prev.loadingItems ? prev : { ...prev, loadingItems: true }));
          const res = await api.post<PreviewData>(
            `/subscriptions/${customer?.subscription!.id}/additional-members/preview`,
            subItems
              .filter(item => item.quantity !== 0)
              .map(item => ({
                price: item.id,
                // quantity: item.metadata.type === PriceMetadataType.PLAN ? item.quantity : Number(membersQuantity),
                quantity: item.quantity,
              }))
          );
          setCheckoutData(prev => ({ ...prev, loadingItems: false }));
          return res.data;
        };
        const infos: string[] = [];
        const planItem = customer?.subscription?.items.data.find(item => item.price.metadata.type === PriceMetadataType.PLAN);
        if (!planItem) {
          // TODO
          // if the user doesn't have a plan associated we need to handle this
          return;
        }
        const planPrice = planItem?.price;
        const additionalItem = customer?.subscription?.items.data.find(item => item.price.id === additional);
        let newQuantity = Number(membersQuantity);

        const getNextSteps = (isDowngrade: boolean, localSubscriptionItems: SubscriptionItemType[], cartItems?: SubscriptionItemType[], localDiscount?: number) => {
          const subscriptionItemsTotal = localSubscriptionItems.reduce((acc, item) => {
            if (item.readonly || item.hide) return acc;
            return acc + item.quantity * item.unitPrice;
          }, 0);

          const localAdditionalItem = localSubscriptionItems.find(item => item.id === additional);
          let membersDif = localAdditionalItem?.quantity!;
          if (additionalItem) {
            if (isDowngrade) {
              membersDif = additionalItem?.quantity! - membersDif;
            } else {
              membersDif -= additionalItem?.quantity!;
            }
          }
          if (isDowngrade) {
            return [
              `Seu novo plano será ativado no dia ${getDateByMs(customer?.subscription?.current_period_end)}, e você receberá uma cobrança de R$ ${subscriptionItemsTotal}/${mapPeriodicityToText(
                planItem?.price.recurring!
              )}.`,
              `A partir dessa data, ${membersDif > 1 ? `${membersDif} integrantes` : `${membersDif} integrante`} da sua assinatura ${membersDif > 1 ? 'perderão' : 'perderá'}  acesso ao Mago Plan.`,
            ];
          }
          const cartTotal = cartItems!.reduce((acc, item) => {
            if (item.readonly || item.hide) return acc;
            return acc + item.quantity * item.unitPrice;
          }, 0);

          return [
            `A partir do dia ${getDateByMs(customer?.subscription?.current_period_end)} sua assinatura será R$ ${subscriptionItemsTotal}/${mapPeriodicityToText(planItem?.price.recurring!)}`,
            `Você será cobrado agora no valor de R$ ${(cartTotal - localDiscount!).toFixed(2).replace('.', ',')}
            em relação ${membersDif > 1 ? `aos ${membersDif} novos membros adicionados` : `ao novo membro adicionado`}`,
          ];
        };
        const getCartItems = (items: SubscriptionItemType[]) => {
          return items.reduce<SubscriptionItemType[]>((arr, item) => {
            if (item.metadata.type === PriceMetadataType.PLAN) return arr;
            let { quantity } = item;
            if (additionalItem) {
              quantity = item.quantity - additionalItem.quantity!;
            }
            return [...arr, { ...item, quantity }];
          }, []);
        };

        let relatedPrice;
        if (additionalItem) {
          if (additionalItem.quantity! > Number(membersQuantity)) {
            isDowngrade = true;
          }
          relatedPrice = additionalItem.price;
        } else {
          setCheckoutData(prev => ({ ...prev, loadingItems: true }));

          const { data } = await api.get<Stripe.Price>(`/prices/${additional}`);
          relatedPrice = data;
        }

        const subscriptionItems: SubscriptionItemType[] = [
          {
            id: planPrice.id,
            name: `Plano | ${planPrice.metadata.members} membros`,
            detail: `Cobrança ${mapPeriodicityToText(planPrice.recurring, true)}`,
            metadata: planPrice?.metadata! as SubscriptionItemType['metadata'],
            unitPrice: planPrice!.unit_amount! / 100,
            quantity: 1,
          },
          {
            id: relatedPrice.id,
            name: 'Profissional Adicional',
            metadata: relatedPrice.metadata! as SubscriptionItemType['metadata'],
            unitPrice: relatedPrice.unit_amount! / 100,
            selectQuantity: true,
            quantity: Number(membersQuantity),
          },
        ];

        if (!isDowngrade) {
          if (additionalItem) {
            newQuantity = Number(membersQuantity) - additionalItem!.quantity!;
          }

          preview = await getPreview(subscriptionItems);
          const cartItems = getCartItems(subscriptionItems);
          cart.push(...cartItems);

          if (preview) {
            discount = newQuantity * (relatedPrice.unit_amount! / 100) - preview.total / 100;
            discountMessage = 'O desconto aplicado é por conta do uso dos profissionais adicionais por um período menor que o período do plano';
          } else {
            overrideTotal = 0;
            infos.push(`Seu plano atual possui ${additionalItem?.quantity} membro(s), a diminuição de membros ocorre apenas ao final do período atual.`);
          }
        }

        setCheckoutData(prev => {
          return {
            ...prev,
            flow: 'additionalMember',
            loading: false,
            isDowngrade,
            discount,
            discountMessage,
            subscriptionItems,
            nextSteps: getNextSteps(isDowngrade, subscriptionItems, cart, discount),
            cart,
            cardReadOnly: true,
            hideBack: true,
            infos,
            overrideTotal,
            overrideOnChangeQuantity: async (subItems, item, value) => {
              let isDowngradeLocal = false;
              let localDiscount: number | undefined;
              let localDiscountMessage: string | undefined;
              const updatedSubscriptionItems = subItems.map(subscriptionItem => {
                return subscriptionItem.metadata.type === item!.metadata.type ? { ...subscriptionItem, quantity: value } : subscriptionItem;
              });
              let localCart = getCartItems(updatedSubscriptionItems);
              let localNextSteps: string[] = [];
              let localInfos: string[] = [];
              if (additionalItem && additionalItem.quantity! > value) {
                overrideTotal = 0;
                isDowngradeLocal = true;
                localCart = [];
                localNextSteps = getNextSteps(isDowngradeLocal, updatedSubscriptionItems, localCart, localDiscount);
              } else if ((!additionalItem && value > 0) || (additionalItem && additionalItem.quantity! < value)) {
                isDowngradeLocal = false;
                setCheckoutData(prev => ({
                  ...prev,
                  loadingItems: true,
                }));
                preview = await getPreview(updatedSubscriptionItems);
                localDiscountMessage = 'O desconto aplicado é por conta do uso dos profissionais adicionais por um período menor que o período do plano';
                if (!additionalItem) {
                  localDiscount = value * subItems[1].unitPrice - preview.total / 100;
                } else {
                  localDiscount = (value - additionalItem.quantity!) * subItems[1].unitPrice - preview.total / 100;
                }
                localNextSteps = getNextSteps(isDowngradeLocal, updatedSubscriptionItems, localCart, localDiscount);
              } else if (!additionalItem && value === 0) {
                localCart = [];
                localNextSteps = [];
                localInfos = ['Mude algum item da assinatura nova para seguir com a alteração do plano'];
              }
              setCheckoutData(prev => {
                return {
                  ...prev,
                  loadingItems: false,
                  isDowngrade: isDowngradeLocal,
                  subscriptionItems: updatedSubscriptionItems,
                  nextSteps: localNextSteps,
                  cart: localCart,
                  discount: localDiscount,
                  infos: localInfos,
                  discountMessage: localDiscountMessage,
                };
              });
            },
            overrideConclude: (nextStepFn, items) => async () => {
              try {
                setCheckoutData(prev => ({ ...prev, loading: true }));
                const newMembers = items.find(item => item.metadata.type === PriceMetadataType.ADDITIONAL_MEMBER)!.quantity;
                const result = await api.patch<Stripe.Subscription | Stripe.SubscriptionSchedule>(`/subscriptions/${customer?.subscription!.id}/additional-members`, {
                  value: newMembers,
                  prorationDate: preview?.prorationDate || undefined,
                });
                const upcoming = await getCustomerUpcomingInvoice(customer.data?.id!);
                if (result.data.object === 'subscription_schedule') {
                  dispatch(setCustomerAdditionalInfoAction(prev => ({ ...prev!, subscriptionSchedule: result.data as Stripe.SubscriptionSchedule, upcoming })));
                  setSuccessPageData({
                    title: 'Alteração agendada com sucesso',
                    subtitle: 'Tudo certo com seu agendamento, na data mostrada, cuidaremos de tudo para você',
                  });
                } else {
                  dispatch(setCustomerAdditionalInfoAction(prev => ({ ...prev!, subscription: result.data as Stripe.Subscription, upcoming })));
                  setSuccessPageData({
                    title: 'Profissionais adicionais contratados com sucesso',
                    subtitle: 'Tudo certo com a sua assinatura, agora você já pode adicionar mais membros na sua empresa',
                  });
                }
                setCheckoutData(checkoutInitialData);
                params.delete('step');
                nextStepFn();
              } catch (error) {
                setCheckoutData(prev => ({ ...prev, loading: false }));
                toast({
                  status: 'error',
                  isClosable: true,
                  title: 'Ocorreu um erro atualizar plano, tente novamente mais tarde',
                });
              }
            },
          };
        });
      }

      const interval: { month: number; year: number; day: number; week: number } = { month: 1, year: 12, day: 0.033333, week: 0.25 };
      if (product) {
        setPrices(prev => ({ ...prev, loading: true }));
        const res = await api.get<Stripe.ApiList<Stripe.Price>>('/prices', { params: { product, type: 'plan' } });

        const sortedPrices = res.data.data.sort((a: Stripe.Price, b: Stripe.Price) => {
          const keyA = a.recurring!.interval_count * interval[a.recurring!.interval];
          const keyB = b.recurring!.interval_count * interval[b.recurring!.interval];
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
        setPrices(prev => ({ ...prev, loading: false, data: sortedPrices }));
      }
    })();
  }, [customer, dispatch, navigate, params, toast]);

  useEffect(() => {
    // Card adding live validation enabled
    if (checkoutData.firstCard.watchErrors) {
      const errors = validateCardData(checkoutData.firstCard.data);
      setCheckoutData(prev => ({ ...prev, firstCard: { ...prev.firstCard, errors, watchErrors: true }, loading: false }));
    }
  }, [checkoutData.firstCard.data, checkoutData.firstCard.watchErrors]);

  const submitHandlerStep1 = useCallback(
    async (isDowngrade: boolean) => {
      setCheckoutData(prev => ({ ...prev, loading: true }));
      let preview: PreviewData;
      const getPreview = async (subItems: SubscriptionItemType[]) => {
        setCheckoutData(prev => (prev.loadingItems ? prev : { ...prev, loadingItems: true }));
        // Get update preview only if its an upgrade
        const res = await api.post<PreviewData>(
          `/customers/${customer.data?.id!}/subscription/preview`,
          subItems
            .filter(item => item.quantity !== 0)
            .map(item => ({
              price: item.id,
              quantity: item.quantity,
            }))
        );
        setCheckoutData(prev => ({ ...prev, loadingItems: false }));
        return res.data;
      };
      const getCheckoutInfo = async (isDowngradeLocal: boolean, localSubscriptionItems: SubscriptionItemType[]) => {
        let localNextSteps: string[] = [];
        let localDiscount: number | undefined;
        let localDiscountMessage: string | undefined;
        const localCart = localSubscriptionItems.filter(item => item.quantity > 0);

        const subscriptionItemsTotal = localSubscriptionItems.reduce((acc, item) => {
          if (item.readonly || item.hide) return acc;
          return acc + item.quantity * item.unitPrice;
        }, 0);
        const subscriptionItemsTotalFormatted = `${subscriptionItemsTotal.toFixed(2).replace('.', ',')}/${mapPeriodicityToText(checkoutData!.selectedPrice!.recurring!)}`;

        if (isDowngradeLocal) {
          localNextSteps = [
            `Seu novo plano será ativado no dia ${getDateByMs(customer?.subscription?.current_period_end)}, e você receberá uma cobrança de R$ ${subscriptionItemsTotal
              .toFixed(2)
              .replace('.', ',')}/${mapPeriodicityToText(checkoutData!.selectedPrice!.recurring!)}.`,
            `Nenhuma cobrança será realizada agora, apenas na data informada.`,
          ];
        } else if (customer?.subscription) {
          setCheckoutData(prev => ({ ...prev, loadingItems: true }));

          preview = await getPreview(localSubscriptionItems);

          localDiscount = preview.discount / 100;
          localDiscountMessage = 'Desconto relacionado ao tempo não utilizado do plano atual';
          const cartTotal =
            localCart.reduce((acc, item) => {
              if (item.readonly || item.hide) return acc;
              return acc + item.quantity * item.unitPrice;
            }, 0) - localDiscount;

          localNextSteps = [
            `Seu novo plano será ativado instantaneamente, e você receberá uma cobrança de R$ ${cartTotal.toFixed(2).replace('.', ',')}`,
            `A partir do próximo mês, o valor da sua assinatura será de R$ ${subscriptionItemsTotalFormatted}.`,
          ];
        } else if (company?.trial_end_at && isTrial) {
          const trialEndDate = new Date(company.trial_end_at).toLocaleDateString('pt-BR');
          localNextSteps = [`Seu novo plano será ativado ao final do período de teste, no dia ${trialEndDate} e você receberá uma cobrança de R$ ${subscriptionItemsTotalFormatted}.`];
        } else {
          localNextSteps = [`Seu novo plano será ativado instantaneamente, e você receberá uma cobrança de R$ ${subscriptionItemsTotalFormatted}.`];
        }
        return {
          preview,
          loading: false,
          loadingItems: false,
          discount: localDiscount,
          discountMessage: localDiscountMessage,
          nextSteps: localNextSteps,
          isDowngrade: isDowngradeLocal,
          subscriptionItems: localSubscriptionItems,
          cart: localCart,
        };
      };

      const subscriptionItems: SubscriptionItemType[] = [
        {
          id: checkoutData!.selectedPrice!.id,
          name: `Plano | ${(checkoutData!.selectedPrice?.product as Stripe.Product).metadata.members} membros`,
          detail: `Cobrança ${mapPeriodicityToText(checkoutData!.selectedPrice!.recurring, true)}`,
          metadata: checkoutData!.selectedPrice?.metadata! as SubscriptionItemType['metadata'],
          unitPrice: checkoutData!.selectedPrice!.unit_amount! / 100,
          quantity: 1,
        },
      ];
      try {
        // Getting plan related addition
        const { data: relatedPrice } = await api.get<Stripe.Price>(`/prices/${checkoutData!.selectedPrice!.id}/related`);
        if (!!relatedPrice && relatedPrice?.unit_amount) {
          subscriptionItems.push({
            id: relatedPrice.id,
            name: 'Profissional Adicional',
            metadata: relatedPrice.metadata! as SubscriptionItemType['metadata'],
            unitPrice: relatedPrice.unit_amount / 100,
            selectQuantity: true,
            quantity: 0,
          });
        }
      } finally {
        const checkoutInfo = await getCheckoutInfo(isDowngrade, subscriptionItems);
        setCheckoutData(prev => {
          return {
            ...prev,
            ...checkoutInfo,
            overrideOnChangeQuantity: async (subItems, item, value) => {
              const updatedSubscriptionItems = subItems.map(subscriptionItem => {
                return subscriptionItem.metadata.type === item!.metadata.type ? { ...subscriptionItem, quantity: value } : subscriptionItem;
              });
              const localCheckoutInfo = await getCheckoutInfo(isDowngrade, updatedSubscriptionItems);
              setCheckoutData(prev => ({
                ...prev,
                ...localCheckoutInfo,
              }));
            },
          };
        });
        nextStep();
      }
    },
    [checkoutData, company?.trial_end_at, customer, isTrial, nextStep]
  );

  const submitHandlerStep2 = useCallback(
    async (isDowngrade: boolean) => {
      setCheckoutData(prev => ({ ...prev, loading: true }));

      //* Counting the total members selected in checkout
      const totalSelectedMembers = checkoutData.subscriptionItems.reduce((acc, item) => {
        if (item.metadata.type === PriceMetadataType.PLAN) {
          return acc + Number(item.metadata.members);
        }
        if (item.metadata.type === PriceMetadataType.ADDITIONAL_MEMBER) {
          return acc + item.quantity;
        }
        return acc;
      }, 0);

      //* If is on trial and if the current amount of members of the company is greater than the selected amount we need to show a warn an block the subscription
      const { data: companyMembers } = await api.get(`/company/${company!.id}/members`);
      if (company?.trial_end_at && isTrial) {
        if (totalSelectedMembers < companyMembers.length) {
          const diff = companyMembers.length - totalSelectedMembers;
          setCheckoutData(prev => ({ ...prev, loading: false }));
          return setWarnDialogState(prev => ({
            ...prev,
            open: true,
            okButtonProps: { colorScheme: 'blue' },
            title: 'Aviso',
            text: `Você tem <b>${companyMembers.length} membros</b> em sua empresa e o plano selecionado tem apenas ${totalSelectedMembers}. Selecione mais ${
              diff > 1 ? `${diff} membros extras` : `${diff} membro extra`
            } ou troque de plano`,
            okText: 'Fechar',
            hideCancel: true,
            onClose: () => setWarnDialogState(defaultWarnDialogState),
            onOk: () => setWarnDialogState(defaultWarnDialogState),
          }));
        }
      }

      let cardId;
      //* If there is a selected card, means that the user already created his first card
      if (checkoutData.selectedCard) {
        cardId = checkoutData.selectedCard.id;
      } else {
        //* Validate first card fields
        const errors = validateCardData(checkoutData.firstCard.data);
        if (Object.keys(errors).length > 0) {
          //* Enabling live validation
          setCheckoutData(prev => ({ ...prev, firstCard: { ...prev.firstCard, errors, watchErrors: true, loading: false } }));
          return;
        }

        //* Add customer card
        const newCard = await addNewCard(customer.data?.id!, checkoutData.firstCard.data).catch(err => {
          setCheckoutData(prev => ({ ...prev, loading: false }));
          if (isAxiosError(err) && err?.response?.data.message) {
            return toast({
              status: 'error',
              isClosable: true,
              title: err.response.data.message,
            });
          }
          return toast({
            status: 'error',
            isClosable: true,
            title: 'Ocorreu um erro ao adicionar cartão',
          });
        });
        cardId = (newCard as Stripe.Card).id;
      }

      if (!cardId) return;
      //* Mounting subscription payload
      const subscriptionPayload: CreateSubscriptionDTO = {
        default_source: cardId,
        members_to_remove: [],
        prorationDate: checkoutData.preview ? checkoutData.preview.prorationDate : undefined,
        items: checkoutData.subscriptionItems
          .filter(item => item.quantity !== 0)
          .map(item => ({
            price: item.id,
            quantity: item.quantity,
          })),
      };

      try {
        //* Submit subscription closure, to not repeat code
        const submitSubscription = async (payload: CreateSubscriptionDTO) => {
          const { data } = await api.post<Stripe.Subscription | Stripe.SubscriptionSchedule>(`/customers/${customer.data?.id!}/subscription`, payload);
          //* If the object property is subscription_schedule, means that the subscription was scheduled to the end of the trial period
          if (data.object === 'subscription_schedule') {
            dispatch(setCustomerAdditionalInfoAction({ subscriptionSchedule: data }));
            setSuccessPageData({
              title: 'Assinatura agendada!',
              subtitle: 'Ao final do período de teste, realizaremos a assinatura de seu plano',
            });
          } else {
            //* Otherwise, means that the subscription was already created
            const subscription = data;
            const cards = await getCustomerCards(customer.data?.id!);
            const upcoming = await getCustomerUpcomingInvoice(customer.data?.id!);
            const subscriptionSchedule = await getCustomerSubscriptionSchedule(customer.data?.id!);
            dispatch(setCustomerAdditionalInfoAction({ subscription, cards, upcoming, subscriptionSchedule }));

            if (subscription.status === SubscriptionStatus.TRIALING) {
              setSuccessPageData({
                title: 'Período de teste iniciado!',
                subtitle: 'Tudo certo com o seu período de teste, agora você já pode adicionar membros na sua empresa',
              });
            } else if (isDowngrade) {
              setSuccessPageData({
                title: 'Atualização de plano agendada!',
                subtitle: `Seu plano será atualizado ao final do período do plano atual, na data ${getDateByMs(subscription.current_period_end)}.`,
              });
            } else {
              setSuccessPageData({
                title: 'Plano assinado com sucesso!',
                subtitle: 'Tudo certo com a sua assinatura, agora você já pode adicionar membros na sua empresa',
              });
            }
          }
          nextStep();
        };

        //* If the current amount of members of the company is greater than the selected amount we need to show a dialog to choose the members
        //* that will be removed when the subscription execute the downgrade
        if (companyMembers.length > totalSelectedMembers) {
          setMembersDialogData(prev => ({
            ...prev,
            open: true,
            overflowNumber: companyMembers.length - totalSelectedMembers,
            members: companyMembers,
            onSubmit: async members => {
              try {
                subscriptionPayload.members_to_remove = members.map(member => member.id);
                await delay(2000);
                await submitSubscription(subscriptionPayload);
                setMembersDialogData(membersDialogDefaultData);
              } catch (error) {
                toast({
                  status: 'error',
                  isClosable: true,
                  title: 'Ocorreu um erro ao gerar assinatura, tente novamente mais tarde',
                });
              }
            },
          }));
        } else {
          await submitSubscription(subscriptionPayload);
        }
      } catch (error) {
        toast({
          status: 'error',
          isClosable: true,
          title: isAxiosError(error) ? error?.response?.data.message : 'Ocorreu um erro ao gerar assinatura, tente novamente mais tarde',
        });
      } finally {
        setCheckoutData(prev => ({ ...prev, loading: false }));
      }
    },
    [checkoutData.firstCard.data, checkoutData.preview, checkoutData.selectedCard, checkoutData.subscriptionItems, company, customer, dispatch, isTrial, nextStep, toast]
  );

  const currentStep = useMemo(() => {
    const isDowngrade = Number(checkoutData!.selectedPrice?.metadata.members) < currentPlanMembers;
    const stepsInfo = {
      0: {
        component: <Step1 prices={prices.data} loading={prices.loading} />,
        onSubmit: () => submitHandlerStep1(isDowngrade),
      },
      1: {
        component: <Step2 />,
        onSubmit: () => submitHandlerStep2(isDowngrade),
      },
    };
    return stepsInfo[activeStep as keyof typeof stepsInfo];
  }, [activeStep, checkoutData, currentPlanMembers, prices.data, prices.loading, submitHandlerStep1, submitHandlerStep2]);

  const disableConcludeButton =
    checkoutData.flow === 'plan'
      ? !checkoutData.selectedPrice || checkoutData.loading || checkoutData.loadingItems
      : !checkoutData.overrideConclude || (!checkoutData.isDowngrade && checkoutData.cart.length === 0) || checkoutData.loading || checkoutData.loadingItems;

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <Container>
      <Flex flexDir='column' width='100%'>
        <CheckoutDataContext.Provider value={[checkoutData, setCheckoutData]}>
          <Steps labelOrientation='vertical' activeStep={activeStep}>
            {steps.map(({ label }) => (
              <Step label={label} key={label}>
                <StepContent>{currentStep?.component}</StepContent>
              </Step>
            ))}
          </Steps>
        </CheckoutDataContext.Provider>
        {activeStep === steps.length ? (
          <CheckoutSuccess {...successPageData} onLeave={() => setSuccessPageData(successPageDefaultData)} />
        ) : (
          <Flex width='100%' justify='center'>
            {!checkoutData.hideBack && !checkoutData.loadingItems && (
              <Button
                isDisabled={checkoutData.loading}
                mr={4}
                onClick={() => {
                  return activeStep === 0 ? handleNavigate(`/${company!.id}/planos-disponiveis`) : prevStep();
                }}
                size='md'
                variant='ghost'
              >
                Anterior
              </Button>
            )}
            <Button
              bgColor='green.500'
              color='white'
              isLoading={checkoutData.loading}
              isDisabled={checkoutData.loading}
              _hover={{ backgroundColor: 'green.600' }}
              _focus={{ boxShadow: '0 0 0 3px green.200' }}
              disabled={disableConcludeButton}
              size='md'
              onClick={checkoutData.overrideConclude ? checkoutData.overrideConclude(nextStep, checkoutData.subscriptionItems) : currentStep?.onSubmit || undefined}
            >
              {isLastStep ? 'Concluir' : 'Próximo'}
            </Button>
          </Flex>
        )}
      </Flex>
      <MembersDialog {...membersDialogData} onClose={() => setMembersDialogData(membersDialogDefaultData)} />
      <ConfirmationModal {...warnDialogState} />
    </Container>
  );
};

export default ChangePlanPage;
