import { Skeleton } from '@chakra-ui/react';
import React from 'react';

import { Content } from './styles';

export interface PlanMembersProps {
  members: number;
  basePrice: number;
  maxDiscount: number;
  gBPerMember: number;
  name: string;
}

const PlanMembersSkeleton = () => (
  <Content>
    <Skeleton height='30px' width='200px' />
    <div>
      <Skeleton height='50px' width='40px' />
      <Skeleton height='20px' width='70px' />
    </div>
    <Skeleton height='20px' width='200px' />
  </Content>
);

const PlanMembers: React.FC<PlanMembersProps> & { Skeleton: React.FC } = ({ members, basePrice, maxDiscount, gBPerMember, name }) => {
  return (
    <Content>
      <span>{name}</span>
      <div className='members'>
        <span>{members}</span>
        <span>{members === 1 ? 'membro incluso' : 'membros inclusos'}</span>
      </div>
      <div className='storage'>
        <span>{gBPerMember * members}</span>
        <span> GB de armazenamento</span>
      </div>
      <div className='price'>
        {maxDiscount > 0 ? 'A partir de' : ''} R$ {(basePrice * (1 - maxDiscount)).toPrecision(4).replace('.', ',')} por usuário/mês
      </div>
    </Content>
  );
};

PlanMembers.Skeleton = PlanMembersSkeleton;

export default PlanMembers;
