import React from 'react';
import Lottie from 'lottie-react-web';
// eslint-disable-next-line import/extensions
import animation from '../../../assets/animations/loading-files.json';

import { Container } from './styles';

export interface AppLoaderProps {
  label: string;
}

const AppLoader: React.FC<AppLoaderProps> = ({ label }) => {
  return (
    <Container>
      <Lottie
        width={400}
        height={400}
        options={{
          animationData: animation,
        }}
      />
      <span>{label}</span>
    </Container>
  );
};

export default AppLoader;
