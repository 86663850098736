import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  position: relative;
  hr {
    margin: ${props => props.theme.spacing.sm} 0;
    color: white;
    border: unset;
    position: relative;
    height: 1px;
    :before {
      content: '';
      position: absolute;
      top: 0;
      left: 5%;
      right: 5%;
      width: 90%;
      height: 1px;
      background-image: linear-gradient(to right, transparent, white, transparent);
    }
  }
`;

export const Aside = styled.aside`
  transition: flex-basis 0.5s;
  flex-grow: 0;
  flex-shrink: 1;
  ${({ theme }) => theme.medias.md} {
    flex-basis: 45px;
  }
  ${({ theme }) => theme.medias.sm} {
    display: none;
  }
  flex-basis: 270px;
  padding: ${props => props.theme.spacing.default};
  background-color: ${props => props.theme.colors.green[500]};
  box-shadow: ${props => props.theme.style['box-shadow']};
  display: flex;
  flex-direction: column;
`;

export const MenuHeader = styled.div`
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.medias.md} {
    justify-content: center;
  }
  justify-content: flex-start;
  span.chakra-avatar {
    ${({ theme }) => theme.medias.md} {
      margin-right: unset;
    }
    margin-right: ${props => props.theme.spacing.sm};
  }
  span {
    :last-child {
      color: white;
      font-weight: 600;
    }
  }
`;

export const MenuItemsWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const MenuItem = styled.div<{ active?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${props => props.theme.spacing.sm};
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: ${props => props.theme.style['border-radius'].default};
  font-size: ${props => props.theme.font.size.default};
  background-color: ${props => (props.active ? props.theme.colors.green[600] : props.theme.colors.green[500])};
  margin: ${props => props.theme.spacing.xs} 0;
  svg {
    ${({ theme }) => theme.medias.md} {
      margin-right: unset;
    }
    margin-right: ${props => props.theme.spacing.sm};
  }
  :hover {
    background-color: ${props => props.theme.colors.green[600]};
  }
`;

const headerHeight = 55;

export const Main = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: hidden;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${props => props.theme.spacing.lg};
  height: ${headerHeight}px;
  background: transparent;
  backdrop-filter: blur(4px);
  width: calc(100% - 15px);
  right: 15px;
  position: absolute;
  z-index: 1;
`;

export const Content = styled.div`
  padding: ${props => props.theme.spacing.lg};
  padding-top: calc(${props => props.theme.spacing.sm} + ${headerHeight}px);
  overflow-y: auto;
  width: 100%;
  min-height: 100%;
`;

export const MenuIconWrapper = styled.div`
  margin-right: ${props => props.theme.spacing.default};
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  button {
    background-color: ${({ theme }) => theme.colors.gray[300]};
  }
`;

export const Title = styled.span`
  font-size: ${({ theme }) => theme.font.size.xl};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray[600]};
`;
