import Stripe from 'stripe';

export const mapPeriodicityToText = (periodicity: Stripe.Price['recurring'], recurrent = false) => {
  const recurrentObj = {
    1: 'Mensal',
    3: 'Trimestral',
    6: 'Semestral',
    12: 'Anual',
  };

  const obj = {
    1: 'Mês',
    3: 'Trimestre',
    6: 'Semestre',
    12: 'Ano',
  };
  let position;
  if (periodicity?.interval === 'month' && periodicity.interval_count === 1) {
    position = 1;
  } else if (periodicity?.interval === 'year' && periodicity.interval_count === 1) {
    position = 12;
  } else if (periodicity?.interval === 'month' && periodicity.interval_count === 3) {
    position = 3;
  } else if (periodicity?.interval === 'month' && periodicity.interval_count === 6) {
    position = 6;
  }
  if (recurrent) {
    return recurrentObj[position as keyof typeof recurrentObj];
  }
  return obj[position as keyof typeof obj];
};

export const mapPeriodicityToMonths = (periodicity: Stripe.Price['recurring']) => {
  const interval: { month: number; year: number; day: number; week: number } = { month: 1, year: 12, day: 0.033333, week: 0.25 };

  return periodicity!.interval_count * interval[periodicity!.interval];
};
